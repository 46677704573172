import globalMessages from 'i18n/Global.messages';

import {
  ADOBE_ANALYTICS_TYPE,
  GOOGLE_ANALYTICS_TYPE,
} from './analytics-integration';
import messages from './constants.messages';
import * as envConfig from './environments';

// Marketing urls
export const TK_WWW_STORIES_URL = '';

// page urlsADMIN_SHOW_EDIT_FEED_URL
export const ADMIN_BASE_URL = '/admin';
export const ADMIN_FEEDS_URL = `${ADMIN_BASE_URL}/feeds`;
export const ADMIN_FEED_URL = `${ADMIN_BASE_URL}/feed`;
export const ADMIN_SHOW_EDIT_FEED_URL = `${ADMIN_FEED_URL}/:feedId`;
export const ADMIN_IMPORT_CONTACT_LIST_URL = `${ADMIN_BASE_URL}/import-contact-list`;
export const ADMIN_ALERTS_SUBSCRIPTION_BASE_URL = `${ADMIN_BASE_URL}/alert-subscription`;
export const ADMIN_IMPORT_CUSTOMER_BASE_URL = `${ADMIN_BASE_URL}/import-customer`;
export const ADMIN_IMPORT_CUSTOMER_V2_BASE_URL = `${ADMIN_BASE_URL}/import-customer-new`;
export const ADMIN_MY_MIGRATIONS_BASE_URL = `${ADMIN_BASE_URL}/my-migrations`;
export const ADMIN_LEGACY_LINKS = `${ADMIN_BASE_URL}/legacy-links`;
export const ADMIN_UNDELETE_SEARCH_DASHBOARD = `${ADMIN_BASE_URL}/undelete`;
export const ADMIN_INFLUENCER_ATTRIBUTION = `${ADMIN_BASE_URL}/influencer-attribution`;
export const ADMIN_SEARCH_CONVERSION_URL = `${ADMIN_BASE_URL}/search-conversion`;
export const ADMIN_DEV_FEATURE_URL = `${ADMIN_BASE_URL}/dev-feature`;
export const ADMIN_ACCOUNT_URL = `${ADMIN_BASE_URL}/account`;
export const ADMIN_SHOW_EDIT_ACCOUNT_URL = `${ADMIN_ACCOUNT_URL}/:accountId`;
export const ADMIN_USER_URL = `${ADMIN_BASE_URL}/user`;
export const ADMIN_SHOW_EDIT_USER_URL = `${ADMIN_USER_URL}/:userId`;
export const ADMIN_USERS_URL = `${ADMIN_BASE_URL}/users`;
export const ADMIN_ARTICLES_URL = `${ADMIN_BASE_URL}/articles`;
export const ADMIN_ANALYSIS_URL = `${ADMIN_BASE_URL}/analysis`;
export const ADMIN_DKIM_MANAGEMENT_BASE_URL = `${ADMIN_BASE_URL}/dkim-management`;
export const ADMIN_DEMO_USER_BASE_URL = `${ADMIN_BASE_URL}/demo-user`;
export const AUTH_URL = '/login/auth';
export const AUTH_REDIRECT_URL = `${AUTH_URL}?redirect=$redirectUrl`;
export const BASE_URL = '/#';
export const BASE_ALERTS_URL_OLD = '/alerts#/digest';
export const BASE_ALERTS_URL_NEW = '/alerts';
export const ALERTS_URL = `${BASE_ALERTS_URL_OLD}/home`;
export const OUTREACH_DEV_FEATURE_MANAGMENT_BASE_URL = `${ADMIN_BASE_URL}/outreach`;
export const GA_DIAGNOSTIC_TOOL_BASE_URL = `${ADMIN_BASE_URL}/ga-tool`;
export const RECIPIENT_UNSUBSCRIPTION_BASE_URL = '/recipient-unsubscribe';
export const RECIPIENT_ALERTS_BASE_URL = '/alert-preferences';
export const ARTICLES_BASE_URL = '/articles';
export const ARTICLES_URL = `${BASE_URL}${ARTICLES_BASE_URL}`;
export const ADMIN_ARTICLE = `${ADMIN_BASE_URL}${ARTICLES_BASE_URL}`;
export const BULK_SEND_BASE_URL = '/send';
export const CALLBACKS_BASE_URL = '/callbacks';
export const CONTACTS_BASE_URL = '/contacts';
export const CONTACTS_URL = '/#/contacts/';
export const CONTACTS_BULK_CREATE_BASE_URL = `${CONTACTS_BASE_URL}-bulk-create`;
export const CAMPAIGNS_BASE_URL = '/campaigns';
export const CAMPAIGNS_URL = `/#${CAMPAIGNS_BASE_URL}/`;
export const DASHBOARD_TEMPLATES_BASE_URL = '/dashboard-templates';
export const DISCOVERY_BASE_URL = '/browse';
export const TOP_CONTENT_BASE_URL = `${DISCOVERY_BASE_URL}/top-content`;
export const DISCOVERY_URL = `/#${DISCOVERY_BASE_URL}/top-content`;
export const DRILLDOWN_BASE_URL = '/drilldowns';
export const DRILLDOWN_REPORT_BASE_URL = '/drilldowns/reports';
export const DRILLDOWN_REPORT_URL = `/#${DRILLDOWN_REPORT_BASE_URL}`;
export const EMAIL_BASE_URL = '/email';
export const EMAIL_ANNOUNCEMENTS_UNSUBSCRIBE_BASE_URL =
  '/announcements/unsubscribe';
export const EMAIL_ANNOUNCEMENTS_UNSUBSCRIBED_BASE_URL =
  '/announcements/unsubscribed';
export const EMAIL_VERIFICATION_BASE_URL = '/email-verification';
export const IMPACT_BASE_URL = '/impact';
export const IMPACT_URL = `/#${IMPACT_BASE_URL}`;
export const INTEGRATIONS_BASE_URL = '/integrations';
export const INFLUENCERS_HUB_BASE_URL = '/influencers';
export const INFLUENCERS_HUB_URL = `/#${INFLUENCERS_HUB_BASE_URL}`;
export const INFLUENCERS_HUB_SEARCH_URL = `${INFLUENCERS_HUB_BASE_URL}/search`;
export const INFLUENCERS_SAVED_LISTS_BASE_URL = `${INFLUENCERS_HUB_BASE_URL}/saved-lists`;
export const INFLUENCERS_IMPORT_LIST_BASE_URL = `${INFLUENCERS_HUB_BASE_URL}/import-list`;
export const INSIGHTS_BASE_URL = '/insights';
export const PRIVATE_INFLUENCERS_BASE_URL = `${INFLUENCERS_HUB_BASE_URL}/private-influencers`;
export const UNSUBSCRIBED_INFLUENCERS_BASE_URL = `${INFLUENCERS_HUB_BASE_URL}/unsubscribed-influencers`;
export const INFLUENCERS_MEDIA_REQUEST_BASE_URL = `${INFLUENCERS_HUB_BASE_URL}/media-requests`;
export const INFLUENCERS_MEDIA_REQUEST_URL = `/#${INFLUENCERS_MEDIA_REQUEST_BASE_URL}`;
export const INFLUENCERS_SAVED_LISTS_URL = `${INFLUENCERS_HUB_URL}/saved-lists`;
export const INFLUENCERS_SAVED_LISTS_TO_CAMPAIGN_URL = `${INFLUENCERS_HUB_BASE_URL}/saved-lists`;
export const INFLUENCERS_MESSAGE_CENTER_BASE_URL = `${INFLUENCERS_HUB_BASE_URL}/messages`;
export const INFLUENCERS_MESSAGE_CENTER_URL = `/#${INFLUENCERS_MESSAGE_CENTER_BASE_URL}`;
export const INFLUENCERS_MESSAGE_CENTER_DRAFTS_URL = `${INFLUENCERS_MESSAGE_CENTER_BASE_URL}/drafts/`;
export const INFLUENCERS_HUB_LIST_RESULTS_URL = `${INFLUENCERS_HUB_BASE_URL}/lists`;
export const INFLUENCERS_SAVED_LIST_RESULTS_URL = `${INFLUENCERS_HUB_URL}/lists`;
export const INFLUENCERS_SEMANTIC_SEARCH_BASE_URL = `${INFLUENCERS_HUB_BASE_URL}/semantic-rec-search`;
export const LEARN_MORE_MARKETING_URL =
  'https://www.cision.com/?utm_medium=product&utm_source=commscloud&utm_content=product&utm_campaign=learnmore';
export const MESSAGE_CENTER_BASE_URL = '/#/contacts/messages/all';
export const OUTREACH_INTEGRATION_REDIRECT_URL =
  '/outreach/integration/redirect';
export const PR_NEWSWIRE_LOGIN =
  'https://cisionccportalqa.prnewswire.com/tk/tkconnect.aspx?p=nro';
export const REPORTS_BASE_URL = '/reports';
export const REACT_SEARCH_BASE_URL = '/searches';
export const SAVED_CONTACTS_URL = '/#/contacts/saved/list/favorites/';
export const EARNED_SEARCHES_BASE_URL = '/earned-searches';
export const EARNED_SEARCH_URL = `/#${EARNED_SEARCHES_BASE_URL}`;
export const EARNED_SEARCH_RESOLVER = `/#${EARNED_SEARCHES_BASE_URL}/{searchId}`;
export const BASIC_EARNED_SEARCH_BASE_URL = `${EARNED_SEARCHES_BASE_URL}/simple`;
export const BASIC_EARNED_SEARCH_URL = `${EARNED_SEARCH_URL}/simple`;
export const ADVANCED_EARNED_SEARCH_BASE_URL = `${EARNED_SEARCHES_BASE_URL}/advanced`;
export const ADVANCED_EARNED_SEARCH_URL = `${EARNED_SEARCH_URL}/advanced`;
export const SEARCH_BASE_URL = '/search#home';
export const SEARCH_URL = `/#${REACT_SEARCH_BASE_URL}/`;
export const SEARCH_CONTACTS_URL = '/#/contacts/search/';
export const SEARCH_HISTORY_BASE_URL = '/search-history';
export const SEARCH_EDIT_BASE_URL = '/search#/edit/';
export const SLACK_KNOWLEDGE_GUIDE_URL =
  '/knowledge-guide?page=slackintegration';
export const SOCIAL_SEARCH_BASE_URL = '/social-feed';
export const CREATE_SOCIAL_SEARCH_BASE_URL = `${SOCIAL_SEARCH_BASE_URL}/create`;
export const SOCIAL_SEARCH_URL = `/#${SOCIAL_SEARCH_BASE_URL}`;
export const KEYWORDS_FEED_SEARCH_BUILDER_BASE_URL = `${SOCIAL_SEARCH_BASE_URL}/keywords-feed`;
export const KEYWORDS_FEED_SEARCH_BUILDER_URL = `${SOCIAL_SEARCH_URL}/keywords-feed`;
export const SIMPLE_KEYWORD_FEED_SEARCH_BUILDER_BASE_URL = `${KEYWORDS_FEED_SEARCH_BUILDER_BASE_URL}/simple`;
export const SIMPLE_KEYWORD_FEED_SEARCH_BUILDER_URL = `${KEYWORDS_FEED_SEARCH_BUILDER_URL}/simple`;
export const ADVANCED_KEYWORD_FEED_SEARCH_BUILDER_BASE_URL = `${KEYWORDS_FEED_SEARCH_BUILDER_BASE_URL}/advanced`;
export const PROFILE_FEED_SEARCH_BUILDER_BASE_URL = `${SOCIAL_SEARCH_BASE_URL}/profile-feed`;
export const DEFAULT_HELP_LINK =
  'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/25765216446?src=-840912917';
export const KNOWLEDGE_GUIDE_ARTICLE_DEFINITION_URL = DEFAULT_HELP_LINK;
export const NEWS_MONITORING_DOCS =
  'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/25764990061';
export const STORIES_BASE_URL = '/stories';
export const STORIES_URL = `/#${STORIES_BASE_URL}/`;
export const STORIES_CREATE_URL = `${STORIES_BASE_URL}/create`;
export const DASHBOARD_BASE_URL = '/dashboard#/';
export const NEW_DASHBOARD_BASE_URL = '/dashboard';
export const NEW_REPORT_BASE_URL = '/report';
export const WIDGET_DRILLDOWN_URL = '/widget';
export const REPORT_PRESENTATION_URL = '/report/presentation';
export const REPORT_URL = `/#${NEW_REPORT_BASE_URL}`;
export const DASHBOARD_HOME_URL = `${DASHBOARD_BASE_URL}home`;
export const DASHBOARD_URL = `/#${NEW_DASHBOARD_BASE_URL}`;
export const NEWSLETTER_BASE_URL = '/alerts#/newsletter/home';
export const NEWSLETTER_ADD_NEWSLETTER_URL = '/alerts#/newsletter/add';
export const NEWSLETTER_EDIT_NEWSLETTER_URL = '/alerts#/newsletter/edit';
export const NEWSLETTER_VIEW_NEWSLETTER_URL = '/newsletter/view';
export const NEW_NEWSLETTER_BASE_URL = `${BASE_ALERTS_URL_NEW}/newsletter`;
export const NEWSLETTER_UNSUBSCRIBED_URL = `${NEW_NEWSLETTER_BASE_URL}/unsubscribed-recipients`;
export const NEWSLETTER_UNSUBSCRIBE_FORM_URL = `${NEW_NEWSLETTER_BASE_URL}/unsubscribe`;
export const ALERT_BASE_URL = '/alerts#/digest/home';
export const APP_SETTINGS_BASE_URL = '/settings/app';
export const MANAGE_DATA_BASE_URL = `${APP_SETTINGS_BASE_URL}/manage-data`;
export const REACT_TAG_BASE_URL = '/tags';
export const TAGS_URL = `/#${REACT_TAG_BASE_URL}`;
export const TRANSLATE_BASE_URL = '/translate';
export const UNIFIED_SETTINGS_BASE_URL = '/settings';
export const UNIFIED_SETTINGS_URL = `/#${UNIFIED_SETTINGS_BASE_URL}`;
export const UNION_METRICS_BASE_URL = 'https://app.unionmetrics.com';
export const UNION_METRICS_LOGIN_URL = `${UNION_METRICS_BASE_URL}/login?tk=1&sso=1`;
export const INFLUENCERS_LOGIN_URL = 'https://search.insightpool.com';
export const SOCIAL_LISTENING_BASE_URL = '/social-listening';
export const SOCIAL_LISTENING_URL = `/#${SOCIAL_LISTENING_BASE_URL}`;
export const ADMIN_MANAGEMENT_DEV_FEATURE_URL = `${ADMIN_BASE_URL}/management/dev-feature`;
export const ADMIN_MANAGEMENT_FEATURE_URL = `${ADMIN_BASE_URL}/management/feature`;
export const WELCOME_HUB_URL = '/welcome';

export const SAVED_SEARCHES_OPTIONS = {
  allSearches: 'ALL_SEARCHES',
  mySearches: 'MY_SEARCHES',
  sharedWithMe: 'SHARED_WITH_ME',
  archivedSearches: 'ARCHIVED_SEARCHES',
  deletedSearches: 'DELETED_SEARCHES',
};

export const ARTICLE_LIST_PAGE_SIZE_LOCAL_STORAGE_ID = 'articleListPageSize';

export const NEWSLETTERS_OPTIONS = {
  allNewsletters: 'ALL_NEWSLETTERS',
  myNewsletters: 'MY_NEWSLETTERS',
  sharedWithMe: 'SHARED_WITH_ME',
};

export const NEWSLETTER_ACCORDIONS = {
  campaigns: 'CAMPAIGNS',
};

export const SAVED_SEARCHES_ACCORDIONS = {
  categories: 'CATEGORIES',
  campaigns: 'CAMPAIGNS',
};

export const REPORTS_ACCORDIONS = {
  campaigns: 'CAMPAIGNS',
};

export const CONTACTS_ACTIVE_SEARCH = 'contactsActiveSearch';

export const LAST_MODIFIED_SORT_OPTION = 'LAST_MODIFIED_SORT_OPTION';
export const NAME_SORT_OPTION = 'NAME_SORT_OPTION';
export const OWNER_SORT_OPTION = 'OWNER_SORT_OPTION';
export const TITLE_SORT_OPTION = 'TITLE_SORT_OPTION';
export const SENT_DATE_SORT_OPTION = 'SENT_DATE_SORT_OPTION';
export const LAST_VIEW_SORT_OPTION = 'LAST_VIEW_SORT_OPTION';

export const EMAIL_ADDRESS = 'EMAIL_ADDRESS';
export const UNSUBSCRIBED_ON = 'UNSUBSCRIBED_ON';

export const ASC_SORT_DIRECTION = 'asc';
export const DESC_SORT_DIRECTION = 'desc';

export const DISCLAIMER_LINK = 'https://www.cision.com/us/legal/privacy-policy';
export const MARKETING_URLS = {
  earnedMediaMonitoring:
    'https://www.cision.com/us/request-demo/earned-media-monitoring/',
  connect: 'https://www.cision.com/us/request-demo/connect/',
  stories: 'https://www.cision.com/us/request-demo/stories/',
  prNewsWire: 'https://www.cision.com/us/request-demo/pr-newswire/',
  earnedMediaImpact:
    'https://www.cision.com/us/request-demo/earned-media-impact/',
  cisionMcdMarketing: 'https://www.trendkite.com/mcd-demo-request',
  influencersMarketing: 'https://www.trendkite.com/influencers',
  prNewswireMarketing: 'https://www.trendkite.com/pr-newswire-demo-request',
  unionMetricsMarketing: 'https://www.trendkite.com/unionmetrics',
};
export const EMAIL_SECURITY_LINK =
  'https://www.cision.com/legal/email-security/';

export const EARNED_SEARCHES_SOCIAL_TRACKERS_SET_UP_URL =
  'https://app.unionmetrics.com/login?tk=1';

// Social streams boolean pill button types
export const SOCIAL_STREAM_BOOLEAN_PILL_TYPES = {
  AND: 'prefixAndLabel',
  OR: 'prefixOrLabel',
  NOT: 'prefixNotLabel',
};
// earned impact tracking links
export const EARNED_IMPACT_TRACKING_LINKS_LINK_DATE_FORMAT = 'YYYYMM';
// utm_campaign parameter should contains date if format YYYYMM + appendix
export const EARNED_IMPACT_NEED_MORE_LINKS_LINK =
  'https://comms.cision.com/Impact_more_URLs_request?utm_medium=product&utm_source=c3popup&utm_content=impact&utm_campaign=';
export const EARNED_IMPACT_NEED_MORE_LINKS_UTM_CAMPAIGN_PARAMETER_APPENDIX =
  '-impactmoreurls';

export const EARNED_IMPACT_LEARNED_MORE_ABOUT_IMPACT_CONVERSIONS_LINK =
  'https://comms.cision.com/Impact_Web_Conversions_request?utm_medium=product&utm_source=c3popup&utm_content=impact&utm_campaign=';
export const EARNED_IMPACT_LEARNED_MORE_ABOUT_IMPACT_CONVERSIONS_UTM_CAMPAIGN_PARAMETER_APPENDIX =
  '-impactwebconversions';
// cookie keys
export const IMPERSONATING_COOKIE_KEY = 'IMPERSONATING';
export const DEV_FEATURE_OVERRIDE_COOKIE_KEY = 'DEVFEATUREOVERRIDE';

// custom tk event names
export const TK_EVENT_LOGOUT = 'trendkite.logout';
export const TK_EVENT_PENDO_GUIDE_LOADED = 'trendkite.pendoGuideLoaded';

// dates
export const DEFAULT_DATE_RANGE_LENGTH_DAYS = 90;
export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'MMM D, YYYY hh:mm A';
export const DEFAULT_DATE_TIME_LOCAL_FORMAT = 'YYYY-MM-DDTHH:mm';
export const OUTREACH_MESSAGE_TIMESTAMP_FORMAT = 'ddd MMM D, YYYY [at] hh:mm A';
export const DEFAULT_DATE_INPUT_FORMAT = 'MMM D, YYYY';
export const DEFAULT_DATE_RANGE_TRAILING_DAY_OPTIONS = [7, 30, 90];
export const DEFAULT_DATE_MANAGE_DATA_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_DATE_SEARCH_FORMAT = 'MM/DD/YY';
export const TWEET_DATE_FORMAT = 'DD MMM YYYY';
export const DEFAULT_DATE_TIME_ZERO_OFFSET_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const UTC_OFFSET = '+00:00';

// i18n date formatting - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
export const DEFAULT_DATE_FORMAT_INTL = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

export const DEFAULT_TIME_FORMAT_INTL = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export const UTC_DATE_FORMAT_INTL = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  timeZone: 'UTC',
};

export const DEFAULT_DATETIME_FORMAT_INTL = {
  ...DEFAULT_DATE_FORMAT_INTL,
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

export const NUMERICAL_VALUE_PLACEHOLDER_CHARACTER = '-';

export const DEFAULT_TITLE_TRUNC_LENGTH = 35;

export const TK_ACCOUNT_ID = 14;

export const OUTLETS_SUGGESTIONS_MAX_LENGTH = 35;
export const ASSET_VALID_UPLOAD_STATUS = '8';
export const ASSET_INCOMPLETE_UPLOAD_STATUS = ['0', '3', '4', '6'];
export const ASSET_TIME_INTERVAL_TO_TRIGGER_API = '5';
export const DEFAULT_ARTICLE_LIST_PAGE_SIZE = 100;
export const SAVED_LIST_INFLUENCERS_NUM_LIMIT = 5000;
export const EMAIL_ANNOUNCEMENTS_MAX_RECIPIENTS = 5000;
export const ES_PAGE_NUM_LIMIT = 10000 / DEFAULT_ARTICLE_LIST_PAGE_SIZE; //ElasticSearch cannot fetch rows past 10K due to setting on cluster
export const NEWSLETTERS_PAGE_SIZE = 50;
export const UNSUBSCRIBED_RECIPIENTS_PAGE_SIZE = 50;
export const FULL_OPT_OUT_ADDRESS_MAX_LENGTH = 120;
export const MAX_LENGTH_ADDRESS_EMAIL_CAMPAIGN = 30;

export const ARTICLE_LIST_TYPES = {
  CAMPAIGNS: {
    DEFAULT: 'CAMPAIGNS_ARTICLE_LIST',
  },
  DISCOVERY: {
    DEFAULT: 'DISCOVERY_ARTICLE_LIST',
    TOP_CONTENT: 'DISCOVERY_TOP_CONTENT',
  },
  DEFAULT: 'ARTICLE_LIST_DEFAULT',
};

export const REASONS_TO_DELETE_ARTICLES = [
  {
    id: 1,
    value: 'sidelink',
    label: 'Sidelink Mention (selected mentions will be deleted)',
  },
  {
    id: 2,
    value: 'duplicate',
    label: 'Duplicate Mention (selected mentions will be deleted)',
  },
  {
    id: 3,
    value: 'filter',
    label: 'Filter Mention (selected mentions will be filtered)',
  },
  {
    id: 4,
    value: 'unspecified',
    label: '',
  },
];

export const IMPACT_SCORE_BUCKET_CUTOFFS = {
  max: 600,
  highMin: 400,
  mediumMin: 150,
};

// page meessage
export const PAGE_MESSAGE_DEFAULT_TTL = 3000;

//page message for increased duration
export const PAGE_MESSAGE_EXTRA_TTL = 7000;

//outreach
export const MAX_OUTREACH_RECIPIENTS = 200;
export const DRAFT_EMAIL_LIST_LIMIT = 2;

// influencers briefing books
export const MAX_BRIEFING_BOOKS_INFLUENCERS = 50;

// author
export const CONTACTS_SORT_OPTIONS = [
  {
    id: 'TOTAL_ARTICLES',
    label: 'Tot. Articles (highest first)',
  },
  {
    id: 'AVG_SHARES',
    label: 'Avg. Shares (highest first)',
  },
  {
    id: 'AVG_ARTICLE_IMPACT',
    label: 'Avg. Article Impact (highest first)',
  },
  {
    id: 'SEO_IMPACT',
    label: 'SEO Impact (highest first)',
  },
];
export const CONTACTS_RANK_SORT_OPTION = {
  id: 'RANK',
  label: 'Recommended (highest first)',
};
export const CONTACTS_SORT_DEFAULT_ID = 'AVG_ARTICLE_IMPACT';
export const CONTACTS_KEYWORD_SEARCH_ID = 'Keyword';
export const CONTACTS_SEARCH_OPTIONS = [
  'Name',
  'Publication',
  CONTACTS_KEYWORD_SEARCH_ID,
];
export const CONTACTS_RESULTS_LIST = 'contactResultsList';

// articles
export const ARTICLES_SORT_OPTIONS = [
  {
    id: 'sort-date',
    label: 'Date',
    labelI18n: 'constantsArticleSortOptionsSortDate',
  },
  {
    id: 'sort-impact-score',
    label: 'Impact Score',
    labelI18n: 'constantsArticleSortOptionsSortImpactScore',
  },
  {
    id: 'sort-readership',
    label: 'Readership',
    labelI18n: 'constantsArticleSortOptionsSortReadership',
  },
  {
    id: 'sort-circulation',
    label: 'Circulation',
    labelI18n: 'constantsArticleSortOptionsSortCirculation',
  },
  {
    id: 'sort-seo-impact',
    label: 'SEO Impact',
    labelI18n: 'constantsArticleSortOptionsSortSeoImpact',
  },
  {
    id: 'sort-shares',
    label: 'Shares',
    labelI18n: 'constantsArticleSortOptionsSortShares',
  },
];
export const ARTICLES_SORT_DEFAULT_ID = 'sort-date';

export const VISUALIZATION_TYPES = {
  area: 'AREA', // V3 only
  timeSeries: 'TIMESERIES', // V2 ONLY
  line: 'LINE', // V3 ONLY
  horizontalBar: 'HORIZONTAL_BAR',
  map: 'MAP',
  pie: 'PIE',
  table: 'TABLE',
  articleList: 'ARTICLE_LIST',
  verticalBar: 'VERTICAL_BAR', // V3 ONLY
  impactTopJournalistList: 'IMPACT_TOP_JOURNALIST_LIST',
  impactSnapshot: 'IMPACT_SNAPSHOT',
  wordCloud: 'WORD_CLOUD',
};

export const METRIC_TYPES = {
  totalReadership: 'TOTAL_READERSHIP',
  aggregateReadership: 'AGGREGATE_READERSHIP',
  ave: 'AVE',
  aveV3: 'AD_EQUIVALENCY',
};

export const DATA_SOURCES = {
  trendkiteSocial: 'TRENDKITE_SOCIAL',
  trendkiteElasticSearch: 'TRENDKITE_ES',
  googleAnalytics: GOOGLE_ANALYTICS_TYPE,
  adobeAnalytics: ADOBE_ANALYTICS_TYPE,
  cidImpact: 'CIDIMPACT',
};

export const DATA_SOURCE_TYPES = {
  socialSearch: 'SOCIAL_SEARCH',
  search: 'SEARCH',
  impactSearch: 'IMPACT_SEARCH',
  parentSearch: 'PARENT_SEARCH',
  childSearch: 'CHILD_SEARCH',
  tag: 'TAG',
  analyticsIntegration: 'ANALYTICS_INTEGRATION',
  impactSnapshot: 'IMPACT_SNAPSHOT',
};

export const SEARCH_DATA_SOURCE_TYPES = [
  DATA_SOURCE_TYPES.search,
  DATA_SOURCE_TYPES.parentSearch,
  DATA_SOURCE_TYPES.childSearch,
  DATA_SOURCE_TYPES.impactSearch,
];

export const DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS = {
  [DATA_SOURCE_TYPES.socialSearch]: 'name',
  [DATA_SOURCE_TYPES.search]: 'title',
  [DATA_SOURCE_TYPES.impactSearch]: 'title',
  [DATA_SOURCE_TYPES.parentSearch]: 'title',
  [DATA_SOURCE_TYPES.childSearch]: 'title',
  [DATA_SOURCE_TYPES.tag]: 'tag',
};

export const V3_MEASURE_OPERATIONS = {
  count: 'COUNT',
  sum: 'SUM',
  average: 'AVG',
  top: 'TOP',
};

export const V3_METRIC_FIELDS = {
  adEquivalency: 'AD_EQUIVALENCY',
  aggregateReadership: 'AGGREGATE_READERSHIP',
  articleMention: 'ARTICLE_MENTION',
  highestReadership: 'monthly_visits_d',
  impactScore: 'impact_score_d',
  mobileReadership: 'monthly_mobile_visits_d',
  desktopReadership: 'monthly_visits_d',
  totalReadership: 'monthly_total_visits_d',
  userTags: 'user_tags_l',
  sentiment: 'sentiment_value',
  sessions: 'SESSIONS',
  pageViews: 'PAGE_VIEWS',
  impactSearch: 'IMPACT_SEARCH',
  uniquePrArticles: 'UNIQUE_PR_ARTICLES',
  prTraffic: 'PR_TRAFFIC',
  totalPosts: 'TOTAL_POSTS',
  topJournalists: 'TOP_JOURNALISTS',
  seoImpactBucket: 'SEO_IMPACT_VALUE_BUCKET',
  earnedMediaAttribution: 'EARNED_MEDIA_ATTRIBUTION',
  word: 'WORD',
};

export const V3_METRIC_LABELS = {
  subSearch: 'SUB_SEARCH',
  tagWidgetV3: 'TAG_WIDGET_V3',
  mentions: 'MENTIONS',
  highestReadership: 'HIGHEST_READERSHIP',
  mobileReadership: 'MOBILE_READERSHIP',
  desktopReadership: 'DESKTOP_READERSHIP',
  totalReadership: 'TOTAL_READERSHIP',
  sessions: 'SESSIONS',
  seoImpactBucket: 'SEO_IMPACT_VALUE_BUCKET',
  views: 'VIEWS',
  dailyUniqueViews: 'DAILY_UNIQUE_VIEWS',
  socialPlatform: 'SOCIAL_PLATFORM',
  aggregateReadership: 'AGGREGATE_READERSHIP',
  impactScore: 'IMPACT_SCORE',
  adEquivalency: 'AD_EQUIVALENCY',
  posts: 'POSTS',
  conversions: 'CONVERSIONS',
  topJournalists: 'TOP_JOURNALISTS',
  impactSnapshot: 'IMPACT_SNAPSHOT',
  earnedMediaAttribution: 'EARNED_MEDIA_ATTRIBUTION',
  word: 'WORD',
};

export const CHART_TYPE = {
  unknown: 'UNKNOWN',
  pie: 'PIE',
  area: 'AREA',
  line: 'LINE',
  areaDateRangeCompare: 'AREA_DATE_RANGE_COMPARE',
  worldMap: 'WORLD_MAP',
  horizontalBar: 'HORIZONTAL_BAR',
  multiVisOverTime: 'MULTI_VISUALIZATION_OVER_TIME',
  wordCloud: 'WORD_CLOUD',
};

export const WIDGET_TYPE = {
  unknown: 'UNKNOWN',
  totalMentionsOverTime: 'TOTAL_MENTIONS_OVER_TIME',
  shareOfVoice: 'SHARE_OF_VOICE',
  socialAmplification: 'SOCIAL_AMPLIFICATION',
  keyMessages: 'KEY_MESSAGES',
  sentiment: 'SENTIMENT',
  articleList: 'ARTICLE_LIST',
  domainAuthority: 'DOMAIN_AUTHORITY',
  international: 'INTERNATIONAL',
  stateProvince: 'STATE_PROVINCE',
  city: 'CITY',
  prReferralTraffic: 'PR_REFERRAL_TRAFFIC',
  totalMentionsVsTotalSessions: 'TOTAL_MENTIONS_VS_TOTAL_SESSIONS',
  highestReaderShip: 'HIGHEST_READERSHIP',
  aggregateReaderShip: 'AGGREGATE_READERSHIP',
  adEquivalency: 'AD_EQUIVALENCY',
  verifiedViews: 'VERIFIED_VIEWS',
  verifiedUniqueViewers: 'VERIFIED_UNIQUE_VIEWERS',
  impactTrendingConversions: 'IMPACT_TRENDING_CONVERSIONS',
  topPublishers: 'TOP_PUBLISHERS',
  topJournalists: 'TOP_JOURNALISTS',
  totalConversions: 'TOTAL_CONVERSIONS',
  socialSearch: 'SOCIAL_SEARCH',
  impactSnapshot: 'IMPACT_SNAPSHOT',
  earnedMediaAttribution: 'EARNED_MEDIA_ATTRIBUTION',
  wordCloud: 'WORD_CLOUD',
};

export const WIDGET_DIMENSION_FIELDS = {
  sentiment: 'sentiment_value',
  domainAuthority: 'impact_score_d',
  totalMentions: 'data_source',
  city: 'city',
  state: 'state',
  country: 'country',
  ave: 'ave',
  publishDate: 'publish_date',
  mediaType: 'mediaType',
  facebook: 'facebook_total_count_l',
  twitter: 'twitter_l',
  pinterest: 'pinterest_l',
  reddit: 'reddit_l',
  publisherUrl: 'publisher_url',
  publisher: 'publisher',
  province: 'province',
  gaDate: 'ga_date',
};

export const WIDGET_DIMENSION_LABELS = {
  search: 'SEARCH',
  facebook: 'FACEBOOK',
  twitter: 'TWITTER',
  pinterest: 'PINTEREST',
  reddit: 'REDDIT',
  sentiment: 'SENTIMENT',
  tag: 'TAG',
  impactScore: 'IMPACT_SCORE',
  country: 'COUNTRY',
  stateProvince: 'STATE/PROVINCE',
  city: 'CITY',
  uniquePrArticles: 'UNIQUE_PR_ARTICLES',
  prTraffic: 'PR_TRAFFIC',
  publishDate: 'PUBLISH_DATE',
  mediaType: 'MEDIA_TYPE',
  publisherUrl: 'PUBLISHER_URL',
};

export const WIDGET_TYPES = {
  widgetV3: 'WIDGET_V3',
  textWidget: 'TEXT_WIDGET',
  imageWidget: 'IMAGE',
};

export const BAR_CHART_METRICS_WITHOUT_DRILLDOWN = ['AVE', 'TOTAL_READERSHIP'];
export const ANALYTICS_INTEGRATION_WITHOUT_DRILLDOWN = [
  'Total Sessions',
  'PR Referral Traffic',
];

export const ORDERED_METRICS = [
  'adEquivalency',
  'localViewership',
  'nationalViewership',
  'gaPageviews',
  'gaAvgSessionDuration',
  'gaPercentNewSessions',
  'gaNewUsers',
  'gaBounceRate',
  'gaGoalCompletionsAll',
  'gaGoalConversionRateAll',
  'gaGoalValueAll',
  'gaTransactionRevenue',
  'adobeBounceRate',
  'adobeCheckouts',
  'adobeOrders',
  'adobePageviews',
  'adobeRevenue',
  'adobeTotalTimeSpent',
  'adobeVisitors',
  'adobeVisits',
  'desktopReadership',
  'mobileReadership',
];

export const FILTER_MEDIA_TYPES = [
  'news',
  'blog',
  'tv',
  'radio',
  'printData',
  'newsedge',
  'manuallyAdded',
  'podcast',
];

export const BLOOMBERG_DATA_SOURCE_PREFIX = 'bloomberg';

export const DATA_SOURCE = {
  tveyesPodcast: {
    // file deepcode ignore HardcodedNonCryptoSecret: <False positive: https://cision.atlassian.net/browse/NGC3CD-911>
    apiKey: 'tveyes_podcast',
  },
  cisionWise: {
    apiKey: 'cision_wise',
  },
  cedrom: {
    apiKey: 'cision_cedrom_broadcast',
  },
  cedromPrint: {
    apiKey: 'cision_cedrom_print',
  },
  newYorkTimesPrint: {
    apiKey: 'new_york_times_print',
  },
  newYorkTimesOnline: {
    apiKey: 'new_york_times_online',
  },
  moreover: {
    apiKey: 'moreover',
    termsUrl: 'http://www.lexisnexis.com/terms/general/',
    privacyUrl: 'http://www.lexisnexis.com/terms/privacy/',
    copyrightUrl: 'http://www.lexisnexis.com/terms/copyright.aspx',
  },
  washingtonPost: {
    apiKey: 'washington_post_online',
  },
  financialTimes: {
    apiKey: 'financial_times',
  },
  factiva: {
    apiKey: 'factiva',
  },
  factivaDowJones: {
    apiKey: 'factiva_dow_jones',
  },
  factivaGlobeAndMail: {
    apiKey: 'factiva_globe_and_mail',
  },
};

export const NCA_LICENSED_CONTENT = 'NCA';
export const NLA_LICENSED_CONTENT = 'NLA';

export const TV_EYES_TYPE = 'tveyes';
export const TV_EYES_PARTNER_ID = 8383;
export const PODCAST_PLAYER_BASE_URL =
  'https://online.tveyes.com/onlineplayer/podcast';
export const PODCAST = 'podcast';
export const CRITICAL_MENTION_TYPE = 'criticalmention';

export const CUSTOM_BROADCAST_URL_STATUS = {
  success: 'SUCCESS',
  pending: 'PENDING',
  failed: 'FAILED',
};

export const LICENSED_CONTENT_LABEL = 'Licensed Content';

export const MEDIA_TYPES = {
  news: {
    apiKey: 'News',
    mediaType: 'NEWS',
    label: 'Online News',
    labelI18n: 'constantsMediaTypesNews',
    placeholderIcon: 'onlineNewsPlaceholder',
  },
  newsLicensed: {
    apiKey: 'NewsLicensed',
    mediaType: 'NEWS_LICENSED',
    label: LICENSED_CONTENT_LABEL,
    labelI18n: 'constantsMediaTypesNewsLicensed',
    placeholderIcon: 'onlineNewsPlaceholder',
  },
  blog: {
    apiKey: 'Blog',
    mediaType: 'BLOGS',
    label: 'Blog',
    labelI18n: 'constantsMediaTypesBlog',
    placeholderIcon: 'blogPlaceholder',
  },
  blogLicensed: {
    apiKey: 'BlogLicensed',
    mediaType: 'BLOGS_LICENSED',
    label: LICENSED_CONTENT_LABEL,
    labelI18n: 'constantsMediaTypesBlogLicensed',
    placeholderIcon: 'blogPlaceholder',
  },
  tv: {
    apiKey: 'TV',
    mediaType: 'TV',
    label: 'TV',
    labelI18n: 'constantsMediaTypesTv',
    placeholderIcon: 'tvPlaceholder',
  },
  radio: {
    apiKey: 'Radio',
    mediaType: 'RADIO',
    label: 'Radio',
    labelI18n: 'constantsMediaTypesRadio',
    placeholderIcon: 'radioPlaceholder',
  },
  broadcast: {
    apiKey: 'broadcast',
    mediaType: 'BROADCAST',
    label: '',
    labelI18n: 'constantsMediaTypesBroadcast',
  },
  podcast: {
    apiKey: 'podcast',
    mediaType: 'PODCAST',
    label: 'Podcast',
    labelI18n: 'constantsMediaTypesPodCast',
    placeholderIcon: 'podcastPlaceholder',
  },
  printData: {
    apiKey: 'print',
    mediaType: 'PRINT',
    label: 'Print',
    labelI18n: 'constantsMediaTypesPrint',
    placeholderIcon: 'printPlaceholder',
  },
  newsedge: {
    apiKey: 'newsedge',
    mediaTYpe: 'NEWSEDGE',
    label: LICENSED_CONTENT_LABEL,
    labelI18n: 'constantsMediaTypesNewsEdge',
  },
  factiva: {
    apiKey: 'factiva',
    mediaTYpe: 'FACTIVA',
    label: 'Factiva',
    labelI18n: 'constantsMediaTypesFactiva',
  },
  financialTimes: {
    apiKey: 'www.ft.com',
    label: 'Financial Times',
    labelI18n: 'constantsMediaTypesFinancialTimes',
  },
  manuallyAdded: {
    apiKey: 'MANUALLY_ADDED',
    label: 'Manually Added',
    labelI18n: 'constantsMediaTypesManuallyAdded',
    placeholderIcon: 'manuallyAddedPlaceholder',
  },
};

export const BROADCAST_MEDIA_TYPES = {
  unassigned: 0,
  tv: 1,
  radio: 2,
};

// The mediaType key in an article is used to determine the article's placeholder icon
// (used if a real image isn't available).  A mediaType of 'broadcast', however, requires
// checking the article's broadcastMediaType, to see if the article is 'tv' or 'radio'.
//
export const GET_MEDIA_TYPE_PLACEHOLDER_ICON = (
  mediaType,
  broadcastMediaType,
) => {
  const broadcastMediaTypeString = Object.keys(BROADCAST_MEDIA_TYPES).find(
    broadcastMediaTypeKey =>
      BROADCAST_MEDIA_TYPES[broadcastMediaTypeKey] === broadcastMediaType,
  );

  let calculatedMediaType = mediaType;
  if (mediaType === 'broadcast') {
    if (broadcastMediaTypeString === 'tv') {
      calculatedMediaType = 'TV';
    } else if (broadcastMediaTypeString === 'radio') {
      calculatedMediaType = 'Radio';
    } else {
      calculatedMediaType = null;
    }
  }

  return MEDIA_TYPES[
    Object.keys(MEDIA_TYPES).find(
      mediaTypeKey => MEDIA_TYPES[mediaTypeKey]?.apiKey === calculatedMediaType,
    )
  ]?.placeholderIcon;
};

export const ONLY_MANUALLY_ADDED = 'ONLY_MANUALLY_ADDED';

export const MEDIA_TYPES_BY_API_KEY = Object.values(MEDIA_TYPES).reduce(
  (acc, curr, index) => {
    return {
      [curr.apiKey]: { ...curr, key: Object.keys(MEDIA_TYPES)[index] },
      ...acc,
    };
  },
  {},
);

export const MEDIA_TYPES_LABELS = {
  media: 'media',
  video: 'video',
  audio: 'audio',
  podcast: 'podcast',
  image: 'image',
};

export const BROADCAST_MEDIA_TYPE_LABELS = [
  MEDIA_TYPES_LABELS.media,
  MEDIA_TYPES_LABELS.video,
  MEDIA_TYPES_LABELS.audio,
];

export const MEDIA_TYPES_LABELS_I18N_MESSAGES = {
  [MEDIA_TYPES_LABELS.media]: {
    label: messages.constantsMedia,
    expired: messages.constantsExpiredMediaLabel,
  },
  [MEDIA_TYPES_LABELS.video]: {
    label: messages.constantsVideo,
    expired: messages.constantsExpiredVideoLabel,
  },
  [MEDIA_TYPES_LABELS.audio]: {
    label: messages.constantsAudio,
    expired: messages.constantsExpiredAudioLabel,
  },
  [MEDIA_TYPES_LABELS.podcast]: {
    label: messages.constantsPodcast,
  },
  [MEDIA_TYPES_LABELS.image]: {
    label: messages.constantsImage,
  },
};

export const ARTICLE_TYPES_LABELS = {
  broadcast: 'Broadcast',
  article: 'Article',
};

export const ARTICLE_TYPES_LABELS_MESSAGES_KEYS = {
  singular: 'singular',
  plural: 'plural',
};

export const ARTICLE_TYPE_LABELS_I18N_MESSAGES = {
  [ARTICLE_TYPES_LABELS.broadcast]: {
    [ARTICLE_TYPES_LABELS_MESSAGES_KEYS.singular]: messages.typeBroadcast,
    [ARTICLE_TYPES_LABELS_MESSAGES_KEYS.plural]: globalMessages.broadcasts,
  },
  [ARTICLE_TYPES_LABELS.article]: {
    [ARTICLE_TYPES_LABELS_MESSAGES_KEYS.singular]: messages.typeArticle,
    [ARTICLE_TYPES_LABELS_MESSAGES_KEYS.plural]: globalMessages.articles,
  },
};

export const SENTIMENT_TYPES = {
  positive: 'positive',
  neutral: 'neutral',
  negative: 'negative',
  undetermined: 'undetermined',
};

export const SOCIAL_STREAM_TYPES = {
  profile: 'profile-search',
  keyword: 'keyword-search',
};

export const SOCIAL_MONITORING_FILTER_TYPES = {
  activity: 'activity',
  country: 'country',
  gender: 'gender',
  hashtag: 'hashtag',
  language: 'language',
  sentiment: 'sentiment',
  url: 'url',
};

export const SOCIAL_KEYWORDS_STREAM_FILTERS_OPERATORS = {
  is: 'is',
  not: 'not',
  between: 'between',
  moreThan: 'morethan',
  lessThan: 'lessthan',
};

export const EARNED_SEARCHES_FILTERS_OPERATORS = {
  is: 'is',
  not: 'not',
  must: 'MUST',
  must_not: 'MUST_NOT',
};

export const EARNED_SEARCHES_LIST_FILTERS = {
  currentUserId: null,
  [SAVED_SEARCHES_OPTIONS.allSearches]: t => !t.dateDeleted && !t.dateArchived,
  [SAVED_SEARCHES_OPTIONS.mySearches]: t =>
    t.userId === EARNED_SEARCHES_LIST_FILTERS.currentUserId &&
    !t.dateDeleted &&
    !t.dateArchived,
  [SAVED_SEARCHES_OPTIONS.sharedWithMe]: t =>
    t.userId !== EARNED_SEARCHES_LIST_FILTERS.currentUserId &&
    !t.dateDeleted &&
    !t.dateArchived,
  [SAVED_SEARCHES_OPTIONS.archivedSearches]: t =>
    !t.dateDeleted && !!t.dateArchived,
  [SAVED_SEARCHES_OPTIONS.deletedSearches]: t => !!t.dateDeleted,
};

export const SOCIAL_SEARCH_MAX_KEYWORDS = 1000;

export const SOCIAL_SEARCH_MAX_CHARACTERS = 2048;

export const PENDO_MAIL_TERMS_GUIDE_ID = 'v2WuLIphCHvDbHf2B6Gj_ZqlVyM';

export const MAX_SEO_IMPACT_SCORE = 100;

export const ANALYTICS_INTEGRATION_TYPES = {
  googleAnalytics: {
    id: 'GOOGLE_ANALYTICS',
    prefix: 'GA',
  },
  adobeAnalytics: {
    id: 'ADOBE_ANALYTICS',
    prefix: 'Adobe',
  },
};

export const WIDGET_ANALYTICS_INTEGRATION_TYPES = {
  1: ANALYTICS_INTEGRATION_TYPES.googleAnalytics.id,
  2: ANALYTICS_INTEGRATION_TYPES.adobeAnalytics.id,
};

export const FEATURES = {
  adobeAnalytics: 'ADOBE_ANALYTICS',
  adultContent: 'ADULT_CONTENT',
  apiAccess: 'API_ACCESS',
  authorProfile: 'AUTHOR_PROFILE',
  brandwatchEmbeddedApplication: 'BRANDWATCH_EMBEDDED_APPLICATION',
  brandwatchUpsellLink: 'BRANDWATCH_UPSELL_LINK',
  broadcastData: 'BROADCAST_DATA',
  bwReportHideSlideThumbs: 'BW_REPORT_HIDE_SLIDE_THUMBS', // new
  americanAccount: 'AMERICAN_ACCOUNT',
  canadianAccount: 'CANADIAN_ACCOUNT',
  emeiaAccount: 'EMEIA_ACCOUNT',
  frenchAccount: 'FRENCH_ACCOUNT',
  cisionInsights: 'CISION_INSIGHTS',
  cisionMCD: 'CISION_MCD',
  connect: 'CONNECT',
  connectBasic: 'CONNECT_BASIC',
  connectSocial: 'CONNECT_SOCIAL',
  earnedImpact: 'EARNED_IMPACT',
  datasourceDuplicateOverride: 'DATASOURCE_DUPLICATE_OVERRIDE', // new
  factivaContentDJ: 'FACTIVA_CONTENT_DJ',
  googleAnalytics: 'GOOGLE_ANALYTICS',
  hideAve: 'HIDE_AVE',
  hideGeoWidgets: 'HIDE_GEO_WIDGETS',
  htmlOnlyAlerts: 'HTML_ONLY_ALERTS', // new
  hubspot: 'HUBSPOT',
  influencers: 'INFLUENCERS',
  ipcb: 'IPCB',
  largeNears: 'LARGE_NEARS',
  lexisNexisQuickViewV1: 'LEXIS_NEXIS_QS_V1',
  licensedContent: 'LICENSED_CONTENT',
  mediaRequests: 'MEDIA_REQUESTS',
  mediaInside: 'MEDIAINSITE',
  monitoring: 'MONITORING',
  newsedge: 'NEWSEDGE',
  newsletter: 'NEWSLETTER',
  newsletterNewTableOfContents: 'NEWSLETTER_NEW_TABLE_OF_CONTENTS', // new
  newsletterWhitelabelFooter: 'NEWSLETTER_WHITELABEL_FOOTER',
  pinpointContacts: 'PINPOINT_CONTACTS',
  podcasts: 'PODCASTS',
  prAttribution: 'PR_ATTRIBUTION', // new
  prAttributionTotalTrafficOverTime: 'PR_ATTRIBUTION_TOTAL_TRAFFIC_OVER_TIME', // new
  printData: 'PRINT',
  printDataUk: 'PRINT_UK',
  prnPressReleases: 'PRN_PRESS_RELEASES', // new
  prwebSso: 'PRWEB_SSO',
  refreshReports: 'REFRESH_REPORTS', // new
  redesignArticleList: 'REDESIGN_ARTICLE_LIST', // new
  reportDrilldownImpactSort: 'REPORT_DRILLDOWN_IMPACT_SORT', // new
  socialMonitoring: 'SOCIAL_MONITORING_INTEGRATION',
  storyHub: 'STORY_HUB',
  topArticlesByVisitorsWidget: 'TOP_ARTICLES_BY_VISITORS_WIDGET', // new
  tvEyesData: 'TV_EYES_DATA',
  tveyesDownloads: 'TVEYES_DOWNLOADS',
  tveyesSwitchDate: 'TVEYES_SWITCH_DATE',
  unionMetrics: 'UNION_METRICS',
  articleReaderAccess: 'ARTICLE_READER_ACCESS',
};

export const DEV_FEATURES = {
  accessControlContactList: 'ACCESS_CONTROL_CONTACT_LIST',
  accessControlGroups: 'ACCESS_CONTROL_GROUPS',
  accessControlSearches: 'ACCESS_CONTROL_SEARCHES',
  accessControlTags: 'ACCESS_CONTROL_TAGS',
  activityFeed: 'ACTIVITY_FEED',
  activityFeedMockData: 'ACTIVITY_FEED_MOCK_DATA',
  addArticlesToTagByUrl: 'ADD_ARTICLES_TO_TAG_BY_URL',
  addChurnZeroTagging: 'ADD_CHURN_ZERO_TAGGING',
  additionalContactFields: 'ADDITIONAL_CONTACT_FIELDS',
  advancedEarnedSearch: 'ADVANCED_EARNED_SEARCH',
  articleImpact: 'ARTICLE_IMPACT',
  articleListDuplicates: 'ARTICLE_LIST_DUPLICATES',
  articleTags: 'ARTICLE_TAGS',
  authorLists: 'AUTHOR_LISTS',
  authorRank: 'AUTHOR_RANK',
  authorRelationship: 'AUTHOR_RELATIONSHIP',
  authorsSearch: 'AUTHOR_LIST_CONTACT_SEARCH',
  backendWidgetV3Cache: 'BACKEND_WIDGET_V3_CACHE',
  benchmarking: 'BENCHMARKING',
  briefingBook: 'BRIEFING_BOOK',
  browseAssetLibrary: 'BROWSE_ASSET_LIBRARY',
  browseAssetLibraryForStandalonePage:
    'BROWSE_ASSET_LIBRARY_FOR_STANDALONE_PAGE',
  browseAssetLibraryFromEmail: 'BROWSE_ASSET_LIBRARY_FROM_EMAIL',
  cacheWidgetData: 'FE_CACHE_WIDGET_DATA',
  bulkUntag: 'BULK_UNTAG',
  bulkTagging: 'BULK_TAGGING',
  campaignArticlesDateRange: 'CAMPAIGN_ARTICLES_DATE_RANGE',
  campaignCoverage: 'CAMPAIGN_COVERAGE',
  campaignMetricsDateRange: 'CAMPAIGN_METRICS_DATE_RANGE',
  campaignWizardDateRange: 'CAMPAIGN_WIZARD_DATE_RANGE',
  campaigns: 'CAMPAIGNS',
  campaignsFuture: 'CAMPAIGNS_FUTURE',
  campaignsNextPhase: 'CAMPAIGNS_PHASE_2',
  cedromDownloadClips: 'CEDROM_DOWNLOAD_CLIPS',
  cisionImpactEarned: 'CISION_IMPACT_EARNED',
  cityWidgetV3: 'CITY_WIDGET_V3',
  connectDataRequests: 'CONNECT_DATA_REQUESTS',
  connectApplyFilter: 'CONNECT_APPLY_FILTER',
  connectLanguageFilterList: 'CONNECT_LANGUAGE_FILTER_LISTS',
  connectListMultiSelect: 'CONNECT_LIST_MULTI_SELECT',
  connectListDuplicate: 'CONNECT_LIST_DUPLICATION',
  connectListMerge: 'CONNECT_LIST_MERGE',
  connectUnsubBadge: 'CONNECT_UNSUB_BADGE',
  contactsKeywordSearch: 'CONTACTS_KEYWORD_SEARCH',
  connectLanguageFilter: 'CONNECT_LANGUAGE_FILTER',
  contactsMarketing: 'CONTACTS_MARKETING',
  connectListSubSearch: 'CONNECT_LIST_SUBSEARCH',
  connectUpdateSharePermissions: 'CONNECT_UPDATE_SHARE_PERMISSIONS',
  connectThreadRedesign: 'CONNECT_THREAD_REDESIGN',
  connectEmailTemplate: 'CONNECT_EMAIL_TEMPLATES',
  connectTrashFolder: 'CONNECT_TRASH_FOLDER',
  connectSentFolder: 'CONNECT_SENT_FOLDER',
  contentEngagementReport: 'CONTENT_ENGAGEMENT_REPORT',
  audienceTypeFilter: 'INFLUENCERS_CONTENT_TYPE_FILTER',
  customInsightsOnly: 'CUSTOM_INSIGHTS_ONLY',
  customerAdmin: 'CUSTOMER_ADMIN',
  dashboardWizard: 'DASHBOARD_WIZARD',
  devCampaignConnectPremium: 'DEV_CAMPAIGN_CONNECT_PREMIUM',
  drillDownPageSizeLimiter: 'DRILL_DOWN_PAGE_SIZE_LIMITER',
  deleteArticleWithReason: 'DELETE_ARTICLE_WITH_REASON',
  desktopUvm: 'DESKTOP_UVM',
  discovery: 'DISCOVERY',
  editSearchColor: 'SEARCH_COLOR_TAG',
  emailAnnouncementsDrafts: 'EMAIL_ANNOUNCEMENTS_DRAFTS',
  emailAnnouncementsInCampaigns: 'EMAIL_ANNOUNCEMENTS_IN_CAMPAIGNS',
  emailAnnouncementsScheduling: 'EMAIL_ANNOUNCEMENTS_SCHEDULING',
  emailAnnouncementsTitle: 'EMAIL_ANNOUNCEMENTS_TITLE',
  emailAnnouncementsWordToHtml: 'EMAIL_ANNOUNCEMENTS_WORD_TO_HTML',
  emailAnnouncementCsvDownload: 'DEV_EMAIL_ANNOUNCEMENT_CSV_DOWNLOAD',
  emailCampaigns: 'EMAIL_ANNOUNCEMENTS',
  emailCampaignsDemoMode: 'EMAIL_ANNOUNCEMENTS_DEMO_MODE',
  financialTimes: 'FINANCIAL_TIMES',
  forceHomeOldDashboards: 'FORCE_HOME_OLD_DASHBOARDS',
  fullTextAccess: 'FULL_TEXT_ACCESS',
  gaTotalMentionsV3: 'GA_TOTAL_MENTIONS_V3',
  headerBar: 'HEADER_BAR',
  impactAttributionConversions: 'IMPACT_ATTRIBUTION_CONVERSIONS',
  impactScore: 'IMPACT_SCORE',
  impactTrendingConversionsWidget: 'IMPACT_TRENDING_CONVERSIONS_WIDGET',
  importCisionPointCustomer: 'IMPORT_CISIONPOINT_CUSTOMER',
  influencerDisplayTargetArea: 'INFLUENCER_DISPLAY_TARGETAREA',
  influencerHub: 'INFLUENCER_HUB_DEV',
  influencerHubBeta: 'INFLUENCER_HUB_BETA',
  influencerHubOnly: 'INFLUENCER_HUB_ONLY',
  influencerMockData: 'INFLUENCER_MOCK_DATA',
  influencerSavedLists: 'INFLUENCER_SAVED_LISTS',
  influencersSuggestionFECaching: 'INFLUENCERS_SUGGESTION_FE_CACHING',
  influencersSuggestionIHubCaching: 'INFLUENCERS_SUGGESTION_IHUB_CACHING',
  influencersFuzzyMatching: 'INFLUENCERS_FUZZY_MATCHING',
  influencersPhoneticMatching: 'INFLUENCERS_PHONETIC_MATCHING',
  influencersSearchHomeBeta: 'INFLUENCERS_SEARCH_HOME_BETA',
  influencersNewSearchHomeToggle: 'CONNECT_NEW_SEARCH_HOME_TOGGLE',
  influencersBulkRemove: 'INFLUENCERS_BULK_REMOVE_FROM_LIST',
  inlineSearchCreation: 'INLINE_SEARCH_CREATION',
  jorts: 'JORTS',
  jortsDrafts: 'JORTS_DRAFTS',
  jortsDraftsBulk: 'JORTS_DRAFTS_BULK',
  jortsEmailBlocker: 'JORTS_EMAIL_BLOCKER',
  jortsMockData: 'JORTS_MOCK_DATA',
  jortsSchedule: 'JORTS_SCHEDULE',
  juneFirst: 'JUNE_FIRST',
  lastUpdatedTimestamp: 'LAST_UPDATED_TIMESTAMP',
  mobileUvm: 'MOBILE_UVM',
  nestedSearches: 'NESTED_SEARCHES',
  newAppSettingsPage: 'NEW_APP_SETTINGS_PAGE',
  newContacts: 'NEW_CONTACTS',
  newDashboards: 'NEW_DASHBOARDS',
  newDefaultNears: 'NEW_DEFAULT_NEARS',
  newDeleteTagStrategy: 'NEW_DELETE_TAG_STRATEGY',
  newDrilldownList: 'NEW_DRILLDOWN_LIST',
  newDrilldownListMultiselect: 'NEW_DRILLDOWN_LIST_MULTISELECT',
  newReports: 'NEW_REPORTS',
  newReportsAddSlideTextWidgetsOptional:
    'NEW_REPORTS_ADD_SLIDE_TEXT_WIDGETS_OPTIONAL',
  newSearchApi: 'NEW_SEARCH_API',
  newTagsView: 'NEW_TAGS_VIEW',
  newsletterCustomizeArticleSummary: 'NEWSLETTER_CUSTOMIZE_ARTICLE_SUMMARY',
  newsletterHideArticle: 'NEWSLETTER_HIDE_ARTICLE',
  newsletterHideArticleSummary: 'NEWSLETTER_HIDE_ARTICLE_SUMMARY',
  newsletterWhitelabelSender: 'NEWSLETTER_WHITELABEL_SENDER',
  oldDashboards: 'OLD_DASHBOARDS',
  oldInfluencers: 'OLD_INFLUENCERS',
  ontologyDashboardTemplates: 'ONTOLOGY_DASHBOARD_TEMPLATES',
  outreachAttachments: 'OUTREACH_ATTACHMENTS',
  outreachIngestionExcludeEmailAddress:
    'OUTREACH_INGESTION_EXCLUDE_EMAILADDRESS',
  passwordManagement: 'PASSWORD_MANAGEMENT',
  performanceMetrics: 'PERFORMANCE_METRICS',
  pinpointShareLists: 'PINPOINT_SHARE_LISTS',
  placeholderGaData: 'PLACEHOLDER_GA_DATA',
  ppEasyFilterTitle: 'PP_EASY_FILTER_TITLE',
  ppTypeAhead: 'PP_TYPE_AHEAD',
  ppcToStoryKitV2: 'PPC_TO_STORY_KIT_V2',
  PrivateInfluencers: 'PRIVATE_INFLUENCERS',
  prTrafficWidgetV3: 'PR_TRAFFIC_WIDGET_V3',
  reactSearchAndTagLists: 'REACT_SEARCH_AND_TAG_LISTS',
  rebranding: 'REBRANDING',
  requestContactInfo: 'REQUEST_CONTACT_INFO',
  responsive: 'RESPONSIVE',
  restrictedContent: 'RESTRICTED_CONTENT',
  scsvArticleExport: 'SCSV_ARTICLE_EXPORT',
  seoImpact: 'SEO_IMPACT',
  sharedDashboards: 'SHARED_DASHBOARDS',
  sharedSearchesViewOnly: 'SHARED_SEARCHES_VIEW_ONLY',
  sharedTags: 'SHARED_TAGS',
  showAllAccountCampaignAssociations: 'SHOW_ALL_ACCOUNT_CAMPAIGN_ASSOCIATIONS',
  similarWebReadership: 'SIMILARWEB_READERSHIP',
  simpleEarnedSearch: 'SIMPLE_EARNED_SEARCH',
  slackArticleSharing: 'SLACK_ARTICLE_SHARING',
  slackV2Auth: 'SLACK_V2_AUTH',
  socialMonitoringIntegration: 'SOCIAL_MONITORING_INTEGRATION_DEV',
  staggeredDashboardLoading: 'STAGGERED_DASHBOARD_LOADING',
  tagWidget: 'TAG_WIDGET',
  tksCrosslink: 'TKS_CROSSLINK',
  translationsReact: 'TRANSLATIONS_REACT',
  tvEyesEdit: 'TV_EYES_EDIT',
  tveyesPodcast: 'TVEYES_PODCAST',
  twitterAudienceInsights: 'TWITTER_AUDIENCE_INSIGHTS',
  urlSearchAllMentions: 'URL_SEARCH_ALL_MENTIONS',
  userAddedArticleFilter: 'USER_ADDED_ARTICLE_FILTER',
  welcomeHub: 'WELCOME_HUB',
  widgetV3: 'WIDGET_V3',
  wordCloud: 'WORD_CLOUD',
  zendeskLinks: 'ZENDESK_LINKS',
  listMembership: 'LIST_MEMBERSHIP',
  totalReadershipCustomWidget: 'TOTAL_READERSHIP_CUSTOM_WIDGET',
  impactMetricsInDashboardDrilldown: 'IMPACT_METRICS_IN_DASHBOARD_DRILLDOWNS',
  allMentionsNewDrilldown: 'ALL_MENTIONS_NEW_DRILLDOWN',
  devListImport: 'DEV_LIST_IMPORT',
  devListImportV2: 'DEV_LIST_IMPORT_V2',
  earnedSearchesV2Support: 'EARNED_SEARCHES_V2_SUPPORT',
  tagsV2Support: 'TAGS_V2_SUPPORT',
  limitRssFeeds: 'LIMIT_RSS_FEEDS',
  drilldownLayoutV2: 'DRILL_DOWN_LAYOUT_V2',
  bulkAddArticle: 'BULK_ADD_ARTICLE',
  impactGraphQLImprovements: 'IMPACT_GRAPHQL_IMPROVEMENTS',
  exportArticlesListToExcel: 'EXPORT_ARTICLES_LIST_TO_EXCEL',
  advancedSearchesFilterSupport: 'ADVANCED_SEARCHES_FILTER_SUPPORT',
  searchMapping: 'SEARCH_MAPPING',
  archiveDeleteSearches: 'ARCHIVE_DELETE_SEARCHES',
  archiveDeleteDashboards: 'ARCHIVE_DELETE_DASHBOARDS',
  earnedMediaAttributionWidget: 'EARNED_MEDIA_ATTRIBUTION_WIDGET',
  enableImportCustomerV2: 'ENABLE_IMPORT_CUSTOMER_V2',
  authorizedDomainSelfServe: 'DEV_AUTHORIZED_DOMAIN_SELFSERVE',
  reportsV3: 'REPORTS_V3',
  connectPrivateDateSort: 'CONNECT_PRIVATE_DATE_SORT',
  googleAnalyticsV4: 'GOOGLE_ANALYTICS_V4',
  canToggleGaVersion: 'CAN_TOGGLE_GA_VERSION',
  dashboardNavigationImproved: 'DASHBOARD_NAVIGATION_IMPROVED',
  exportReportsToPowerPoint: 'EXPORT_REPORTS_TO_POWERPOINT',
  mediaRequestConnectivelyPageMask: 'MEDIA_REQUESTS_CONNECVTIVELY_PAGE_MASK',
  connectListCopy: 'CONNECT_LIST_COPY',
  connectUnsubList: 'CONNECT_UNSUB_LIST',
  configurableLinkTracking: 'CONNECT_CONFIGURABLE_LINK_TRACKING',
  enableDkimSignedStatus: 'CONNECT_DKIM_SIGNED_STATUS',
  sendgridPitch: 'SENDGRID_PITCH',
};

export const USER_ROLES = {
  adminRole: 'ROLE_ADMIN',
  analystRole: 'ROLE_ANALYST',
  customerAdminRole: 'ROLE_CUSTOMER_ADMIN',
  templateCreatorRole: 'ROLE_TEMPLATE_CREATOR',
  impersonatorOutreach: 'ROLE_IMPERSONATOR_OUTREACH',
  translator: 'ROLE_TRANSLATOR',
  adminLiteRole: 'ROLE_ADMIN_LITE',
  featureToggle: 'ROLE_FEATURE_TOGGLE',
  impactAdmin: 'ROLE_IMPACT_ADMIN',
  contactListImporter: 'ROLE_CONTACT_LIST_IMPORTER',
  deactivateAccountRole: 'ROLE_ADMIN_INT_ACCT_DEACTIVATE',
  approverRole: 'ROLE_APPROVER',
  auditRole: 'ROLE_AUDIT',
  contentSearchAdminRole: 'ROLE_ADMIN_INT_CONTENT_SEARCH',
  impersonatorRole: 'ROLE_IMPERSONATOR',
};

export const TOP_LEVEL_PAGES = [
  {
    href: '/search#home',
    id: 'searches-homepage-link',
    label: 'Searches',
    legacy: true,
    locationShort: '/search#home',
    disabledDevFeatures: [DEV_FEATURES.discovery],
    optionalFeatures: [],
    requiredFeatures: [],
    requiredDevFeatures: [],
  },
  {
    href: '/#/browse/top-content',
    id: 'discovery-homepage-link',
    label: 'Coverage',
    legacy: false,
    locationShort: '/browse/top-content',
    optionalFeatures: [],
    requiredFeatures: [],
    requiredDevFeatures: [DEV_FEATURES.discovery],
  },
  {
    href: '/#/campaigns/',
    id: 'campaigns-homepage-link',
    label: 'Campaigns',
    legacy: false,
    locationShort: '/campaigns',
    optionalFeatures: [],
    requiredFeatures: [],
    requiredDevFeatures: [],
  },
  {
    href: '/#/impact/earned',
    id: 'impact-earned-link',
    label: 'Impact',
    legacy: false,
    locationShort: '/impact/earned',
    optionalFeatures: [],
    requiredFeatures: [],
    requiredDevFeatures: [DEV_FEATURES.cisionImpactEarned],
  },
  {
    href: `/#${STORIES_BASE_URL}/`,
    id: 'stories-homepage-link',
    label: 'Stories',
    legacy: false,
    locationShort: STORIES_BASE_URL,
    optionalFeatures: [],
    requiredFeatures: [FEATURES.storyHub],
    requiredDevFeatures: [],
  },
  {
    href: '/dashboard',
    id: 'dashboards-homepage-link',
    label: 'Dashboards',
    legacy: true,
    locationShort: '/dashboard',
    optionalFeatures: [],
    requiredFeatures: [],
    requiredDevFeatures: [],
  },
  {
    href: '/reports',
    id: 'reports-homepage-link',
    label: 'Reports',
    legacy: true,
    locationShort: '/reports',
    optionalFeatures: [],
    requiredFeatures: [],
    requiredDevFeatures: [],
  },
  {
    href: '/alerts',
    id: 'alrts-homepage-link',
    label: 'Alerts',
    legacy: true,
    locationShort: '/alerts',
    optionalFeatures: [],
    requiredFeatures: [],
    requiredDevFeatures: [],
  },
  {
    href: '/#/contacts/',
    id: 'contacts-homepage-link',
    label: 'Contacts',
    legacy: false,
    locationShort: '/contacts',
    optionalFeatures: [],
    requiredFeatures: [FEATURES.pinpointContacts],
    requiredDevFeatures: [],
  },
  {
    href: '/#/contacts-overview/',
    id: 'contacts-overview-link',
    label: 'Contacts',
    legacy: false,
    locationShort: '/contacts-overview',
    optionalFeatures: [],
    requiredFeatures: [],
    requiredDevFeatures: [],
    disabledFeatures: [FEATURES.pinpointContacts],
  },
  {
    href: UNION_METRICS_LOGIN_URL,
    id: 'union-metrics-link',
    label: 'Social',
    legacy: true,
    locationShort: UNION_METRICS_LOGIN_URL,
    inNewTab: true,
    optionalFeatures: [],
    requiredFeatures: [FEATURES.unionMetrics],
    requiredDevFeatures: [],
  },
  {
    href: MARKETING_URLS.unionMetricsMarketing,
    id: 'union-metrics-marketing-link',
    label: 'Social',
    legacy: true,
    locationShort: MARKETING_URLS.unionMetricsMarketing,
    inNewTab: true,
    newBadge: true,
    optionalFeatures: [],
    requiredFeatures: [],
    disabledFeatures: [FEATURES.unionMetrics],
    requiredDevFeatures: [],
  },
  {
    href: INFLUENCERS_LOGIN_URL,
    id: 'influencers-link',
    label: 'Influencers',
    legacy: true,
    locationShort: INFLUENCERS_LOGIN_URL,
    inNewTab: true,
    optionalFeatures: [],
    requiredFeatures: [FEATURES.influencers],
    requiredDevFeatures: [],
  },
  {
    href: MARKETING_URLS.influencersMarketing,
    id: 'influencers-marketing-link',
    label: 'Influencers',
    legacy: true,
    locationShort: MARKETING_URLS.influencersMarketing,
    inNewTab: true,
    newBadge: true,
    optionalFeatures: [],
    requiredFeatures: [],
    disabledFeatures: [FEATURES.influencers],
    requiredDevFeatures: [],
  },
  {
    href: '/#/influencers/',
    id: 'influencers-hub-homepage-link',
    label: 'InfluencersHub',
    legacy: false,
    locationShort: '/influencers',
    optionalFeatures: [],
    requiredFeatures: [FEATURES.connect],
    requiredDevFeatures: [],
  },
];

export const TIME_UNITS = {
  second: 'second',
  minute: 'minute',
  hour: 'hour',
  day: 'day',
  month: 'month',
  year: 'year',
};

export const VALUE_FORMATTER_DISPLAY_TYPES = {
  number: 'number',
  shortNumber: 'shortNumber',
  percentGivenZeroToOneScale: 'percentGivenZeroToOneScale',
  percentGivenZeroToHundredScale: 'percentGivenZeroToHundredScale',
  currencyUSD: 'currencyUSD',
  shortCurrencyUSD: 'shortCurrencyUSD',
  shortCurrencyDynamic: 'shortCurrencyDynamic',
  timeGivenSeconds: 'timeGivenSeconds',
};

export const VALUE_FORMATTER_DISPLAY_FORMATS = {
  number: '0,0.[00]',
  shortNumber: '0,0[.]0a',
  percent: '0.[00]%',
  currencyUSD: '$0,0.00',
  currencyUSDRounded: '$0,0',
  currencyDynamicRounded: '0,0',
  currencyUSDAbbreviated: '$0,0[.]0a',
  currencyDynamicAbbreviated: '0,0[.]0a',
  minutesAndSeconds: 'mm:ss',
  hoursMinutesAndSeconds: 'HH:mm:ss',
};

export const CURRENCY_MAP = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: 'AMD',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: 'Bs.',
  BRL: 'R$',
  BSD: '$',
  BTC: '฿',
  BTN: 'Nu.',
  BWP: 'P',
  BYR: 'p.',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHF: 'Fr.',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  YER: '﷼',
  ZAR: 'R',
  ZWD: 'Z$',
};

export const ARTICLE_DETAIL_IMPACT_FIELDS = {
  default: [
    {
      key: 'readership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'sentiment',
      format: 'SentimentLabel',
    },
  ],
  broadcast: [
    {
      key: 'nationalViewership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'localViewership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'sentiment',
      format: 'SentimentLabel',
    },
  ],
  mobileUvm: [
    {
      key: 'totalReadership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'sentiment',
      format: 'SentimentLabel',
    },
  ],
};

export const ARTICLE_DETAIL_KPIS_FIELDS = {
  default: [
    {
      key: 'sentiment',
      format: 'SentimentLabel',
    },
    {
      key: 'readership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'adEquivalency',
      format: VALUE_FORMATTER_DISPLAY_TYPES.shortCurrencyUSD,
    },
  ],
  dynamic: [
    {
      key: 'sentiment',
      format: 'SentimentLabel',
    },
    {
      key: 'readership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'adEquivalency',
      format: VALUE_FORMATTER_DISPLAY_TYPES.shortCurrencyDynamic,
    },
  ],
  getBroadcast: isoCode => [
    {
      key: 'sentiment',
      format: 'SentimentLabel',
    },
    {
      key: 'nationalViewership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'localViewership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'viewershipAdValue',
      format:
        isoCode && isoCode !== CURRENCY_MAP.USD
          ? VALUE_FORMATTER_DISPLAY_TYPES.shortCurrencyDynamic
          : VALUE_FORMATTER_DISPLAY_TYPES.shortCurrencyUSD,
    },
  ],
  getMobileUvm: isoCode => [
    {
      key: 'sentiment',
      format: 'SentimentLabel',
    },
    {
      key: 'readership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'mobileReadership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'totalReadership',
      format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    },
    {
      key: 'adEquivalency',
      format:
        isoCode && isoCode !== CURRENCY_MAP.USD
          ? VALUE_FORMATTER_DISPLAY_TYPES.shortCurrencyDynamic
          : VALUE_FORMATTER_DISPLAY_TYPES.shortCurrencyUSD,
    },
  ],
};

export const ARTICLE_METRICS_BASE_FIELDS = [
  'sentiment',
  'readership',
  'adEquivalency',
];

export const ARTICLE_METRICS_BROADCAST_FIELDS = [
  'nationalViewership',
  'localViewership',
  'viewershipAdValue',
];

export const ARTICLE_METRICS_GA_FIELDS = [
  'gaAvgSessionDuration',
  'gaBounceRate',
  'gaGoalCompletionsAll',
  'gaGoalConversionRateAll',
  'gaGoalValueAll',
  'gaNewUsers',
  'gaPageviews',
  'gaPageviewsPerSession',
  'gaPercentNewSessions',
  'gaSessions',
  'gaTransactionRevenue',
];

export const ARTICLE_METRICS_AA_FIELDS = [
  'adobeBounceRate',
  'adobeCheckouts',
  'adobeOrders',
  'adobePageviews',
  'adobeRevenue',
  'adobeTotalTimeSpent',
  'adobeVisitors',
  'adobeVisits',
];

export const ARTICLE_METRICS_UVM_FIELDS = [
  'desktopReadership',
  'mobileReadership',
  'totalReadership',
];

export const ARTICLE_STATS = {
  articleImpact: {
    label: 'Impact',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'avg',
  },
  seoImpact: {
    label: 'SEO Impact',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'avg',
  },
  readership: {
    label: 'Readership',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  socialSum: {
    label: 'Total Shares',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  sentiment: {
    label: 'Sentiment',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'none',
  },
  sentimentValue: {
    label: 'Sentiment Value',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'avg',
  },
};

export const SOCIAL_ARTICLE_STATS = {
  socialFacebookTotal: {
    label: 'Facebook Shares',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  socialTwitter: {
    label: 'Twitter Shares',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  socialPinterest: {
    label: 'Pinterest Shares',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  socialLinkedin: {
    label: 'LinkedIn Shares',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  socialGoogleplus: {
    label: 'Google+ Shares',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
};

// These fields are only used when requesting exports from the server
export const EXPORT_METRICS = [
  'adEquiv',
  'author',
  'closed',
  'date',
  'headline',
  'impactScore',
  'mediaType',
  'opportunities',
  'pipeline',
  'seoImpact',
  'shares',
  'tags',
];

export const ARTICLE_METRICS = {
  readership: {
    label: 'Readership',
    labelI18n: 'constantsReadershipArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  localViewership: {
    label: 'Local Audience',
    labelI18n: 'constantsLocalViewershipArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  nationalViewership: {
    label: 'National Audience',
    labelI18n: 'constantsNationalViewershipArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  adEquivalency: {
    label: 'Ad Equivalency',
    labelI18n: 'constantsAdEquivalencyArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.currencyUSD,
    duplicateAggregationMethod: 'sum',
  },
  viewershipAdValue: {
    label: 'Cost Per Minute',
    labelI18n: 'constantsViewershipAdValueArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.currencyUSD,
    duplicateAggregationMethod: 'sum',
  },
  sentiment: {
    label: 'Sentiment',
    labelI18n: 'constantsSentimentArticleMetric',
    format: 'SentimentLabel',
    duplicateAggregationMethod: 'sum',
  },
  socialSum: {
    label: 'Total Shares',
    labelI18n: 'constantsSocialSumArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  gaPageviews: {
    label: 'Page Views',
    labelI18n: 'constantsGaPageviewsArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  gaSessions: {
    label: 'Sessions',
    labelI18n: 'constantsGaSessionsArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  gaAvgSessionDuration: {
    label: 'Avg. Duration',
    labelI18n: 'constantsGaAvgSessionDurationArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.timeGivenSeconds,
    duplicateAggregationMethod: 'avg',
  },
  gaPercentNewSessions: {
    label: '% New Sessions',
    labelI18n: 'constantsGaPercentNewSessionsArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.percentGivenZeroToHundredScale,
    duplicateAggregationMethod: 'avg',
  },
  gaNewUsers: {
    label: 'New Users',
    labelI18n: 'constantsGaNewUsersArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  gaBounceRate: {
    label: 'Bounce Rate',
    labelI18n: 'constantsGaBounceRateArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.percentGivenZeroToHundredScale,
    duplicateAggregationMethod: 'sum',
  },
  gaPageviewsPerSession: {
    label: 'Pages/Sessions',
    labelI18n: 'constantsGaPageviewsPerSessionArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  gaGoalConversionRateAll: {
    label: 'Rate',
    labelI18n: 'constantsGaGoalConversionRateAllArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.percentGivenZeroToHundredScale,
    duplicateAggregationMethod: 'sum',
  },
  gaGoalCompletionsAll: {
    label: 'Completions',
    labelI18n: 'constantsGaGoalCompletionsAllArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  gaGoalValueAll: {
    label: 'Value',
    labelI18n: 'constantsGaGoalValueAllArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.currencyUSD,
    duplicateAggregationMethod: 'sum',
  },
  gaTransactionRevenue: {
    label: 'Transaction Revenue',
    labelI18n: 'constantsGaTransactionRevenueArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.currencyUSD,
    duplicateAggregationMethod: 'sum',
  },
  adobeBounceRate: {
    label: 'Bounce Rate',
    labelI18n: 'constantsAdobeBounceRateArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.percentGivenZeroToOneScale,
    duplicateAggregationMethod: 'sum',
  },
  adobeCheckouts: {
    label: 'Checkouts',
    labelI18n: 'constantsAdobeCheckoutsArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  adobeOrders: {
    label: 'Orders',
    labelI18n: 'constantsAdobeOrdersArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  adobePageviews: {
    label: 'Page Views',
    labelI18n: 'constantsAdobePageviewsArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  adobeRevenue: {
    label: 'Revenue',
    labelI18n: 'constantsAdobeRevenueArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.currencyUSD,
    duplicateAggregationMethod: 'sum',
  },
  adobeTotalTimeSpent: {
    label: 'Avg Time on Website',
    labelI18n: 'constantsAdobeTotalTimeSpentArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.timeGivenSeconds,
    duplicateAggregationMethod: 'sum',
  },
  adobeVisitors: {
    label: 'Visitors',
    labelI18n: 'constantsAdobeVisitorsArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  adobeVisits: {
    label: 'Website Visits',
    labelI18n: 'constantsAdobeVisitsArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  desktopReadership: {
    label: 'Desktop Readership',
    labelI18n: 'constantsDesktopReadershipArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  mobileReadership: {
    label: 'Mobile Readership',
    labelI18n: 'constantsMobileReadershipArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
  totalReadership: {
    label: 'Total Readership',
    labelI18n: 'constantsTotalReadershipArticleMetric',
    format: VALUE_FORMATTER_DISPLAY_TYPES.number,
    duplicateAggregationMethod: 'sum',
  },
};

/* eslint-disable max-len */
export const FIRST_TIME_USE_EMPTY_STATE_MESSAGES = {
  campaign:
    'Campaigns are a great way to organize any searches, dashboards, newsletters, reports and alerts that are related to an initiative.',
  campaignContacts:
    'Looks like you haven’t added any contacts to this campaign yet. Add any contacts related to this campaign and you’ll be able to keep track of high-level metrics and coverage.',
  campaignNewsletter:
    'Looks like you haven’t added any newsletters to this campaign yet. Add any newsletters related to this campaign and you’ll be able to keep them organized.',
  campaignReport:
    'Looks like you haven’t added any reports to this campaign yet. Add any reports related to this campaign and you’ll be able to keep them organized.',
  campaignSearch:
    'Looks like you haven’t added any searches to this campaign yet. Add any searches related to this campaign and you’ll be able to keep track of high-level metrics and coverage.',
  campaignStories:
    'Looks like you haven’t added any story kits to this campaign yet. Add any story kits related to this campaign and you’ll be able to keep them organized.',
  storyPerformancePreLaunchMessage:
    'Once your Story Kit is published this section will allow you to track how your Story Kit is performing. You will be able to: track coverage of your story, see which contacts are the most engaged, see what content is performing the best, and set performance goals.', // eslint-disable-line max-len
  campaignAlert:
    'Looks like you haven’t added any alerts to this campaign yet. Add any alerts related to this campaign and you’ll be able to keep them organized.',
  campaignList:
    'Looks like you haven’t added any lists to this campaign yet. Add any lists related to this campaign and you’ll be able to keep them organized.',
};
/* eslint-enable max-len */

export const CAMPAIGN_WIZARD_EMPTY_STATE_MESSAGES = {
  contacts: messages.constantsCampaignWizardContactsEmptyState,
  dashboards: messages.constantsCampaignWizardDashboardsEmptyState,
  newsletters: messages.constantsCampaignWizardNewslettersEmptyState,
  alerts: messages.constantsCampaignWizardAlertsEmptyState,
  reports: messages.constantsCampaignWizardReportsEmptyState,
  searches: messages.constantsCampaignWizardSearchesEmptyState,
  stories: messages.constantsCampaignWizardStoriesEmptyState,
};

// ---------  Campaign Wizard Steps ----------
// You will need to add your step to CAMPAIGN_WIZARD_STEPS_BY_ID,
// CAMPAIGN_WIZARD_STEPS, and in the CampaignWizard.jsx list
export const CAMPAIGN_WIZARD_STEPS_BY_ID = {
  campaignDetails: 0,
  addPrimarySearch: 1,
  addSearches: 2,
  addContacts: 3,
  addInfluencerLists: 4,
  addDashboards: 5,
  addReports: 6,
  addNewsletters: 7,
  addAlerts: 8,
  addStories: 9,
};

export const CAMPAIGN_SIDEBAR_CONTENT = {
  addPrimarySearch: 'addPrimarySearch',
  changePrimarySearch: 'changePrimarySearch',
};

export const DRILLDOWN_COLUMNS_KEYS = {
  author: 'AUTHOR',
  outlet: 'OUTLET',
  publicationDate: 'PUB_DATE',
};

export const CAMPAIGN_WIDGET_DRILLDOWN_COLUMNS = {
  AUTHOR: DRILLDOWN_COLUMNS_KEYS.author,
  OUTLET: DRILLDOWN_COLUMNS_KEYS.outlet,
  PUB_DATE: DRILLDOWN_COLUMNS_KEYS.publicationDate,
};

export const OUTLET_AND_AUTHOR_DIVIDER = '&';
export const MENTION_COLUMN_HEADERS = [
  DRILLDOWN_COLUMNS_KEYS.publicationDate,
  DRILLDOWN_COLUMNS_KEYS.outlet,
  OUTLET_AND_AUTHOR_DIVIDER,
  DRILLDOWN_COLUMNS_KEYS.author,
];

export const CAMPAIGN_WIZARD_STEPS = [
  {
    id: 'campaignDetails',
    title: 'constantsCampaignWizardAddDetailsStep',
    hasNav: false,
    valid: false,
    skippable: false,
    data: {
      title: '',
      category: '',
      description: '',
      dateRange: 'TRAILING_30',
    },
  },
  {
    id: 'addPrimarySearch',
    title: 'constantsCampaignWizardAddPrimarySearchStep',
    hasNav: true,
    valid: true,
    skippable: false,
    data: {
      primarySearch: null,
    },
  },
  {
    id: 'addSearches',
    title: 'constantsCampaignWizardAddSearchesStep',
    skippable: true,
    hasNav: true,
    valid: true,
    data: {
      searches: [],
    },
  },
  {
    id: 'addContacts',
    title: 'constantsCampaignWizardAddContactsStep',
    hasNav: true,
    skippable: true,
    valid: true,
    data: {
      filters: [],
      query: '',
      contacts: [],
    },
    excludedFeatures: [FEATURES.connect],
  },
  {
    id: 'addInfluencerLists',
    title: 'constantsCampaignWizardAddListStep',
    hasNav: true,
    skippable: true,
    valid: true,
    data: {
      influencerLists: [],
    },
    requiredFeatures: [FEATURES.connect],
  },
  {
    id: 'addDashboards',
    title: 'constantsCampaignWizardAddDashboardsStep',
    hasNav: true,
    skippable: true,
    valid: true,
    data: {
      dashboards: [],
    },
  },
  {
    id: 'addReports',
    title: 'constantsCampaignWizardAddReportsStep',
    hasNav: true,
    skippable: true,
    valid: true,
    data: {
      reports: [],
    },
  },
  {
    id: 'addNewsletters',
    title: 'constantsCampaignWizardAddNewsLettersStep',
    hasNav: true,
    skippable: true,
    valid: true,
    data: {
      newsletters: [],
    },
    requiredFeatures: [FEATURES.newsletter],
  },
  {
    id: 'addAlerts',
    title: 'constantsCampaignWizardAddAlertsStep',
    hasNav: true,
    skippable: true,
    valid: true,
    data: {
      alerts: [],
    },
  },
  {
    id: 'addStories',
    title: 'constantsCampaignWizardAddStoriesStep',
    hasNav: true,
    skippable: true,
    valid: true,
    data: {
      stories: [],
    },
    requiredFeatures: [FEATURES.storyHub],
  },
];

export const NYLAS_DEV_OAUTH_PARAMS = {
  client_id: 'bss9kder1orp57xcfvg1ulf0l',
  response_type: 'code',
  scopes: 'email.read_only,email.send',
};

export const NYLAS_OAUTH_PARAMS = {
  client_id: 'e57oul2pk1p0yt2d09m1f8198',
  response_type: 'code',
  scopes: 'email.read_only,email.send',
};

export const NYLAS_DEV_OAUTH_PARAMS_OLD = {
  client_id: '6t1ul2x4amaj088btdi3lpafm',
  response_type: 'code',
  scope: 'email',
};

export const NYLAS_OAUTH_PARAMS_OLD = {
  client_id: '6yhcclzf9e51bltc82ewvlobe',
  response_type: 'code',
  scope: 'email',
};

export const SLACK_OAUTH_PARAMS = {
  scope: 'identify,incoming-webhook',
  client_id: '264122818946.286640904903',
};

export const SLACK_OAUTH_V2_PARAMS = {
  scope: 'incoming-webhook',
  client_id:
    envConfig.currentEnvironment === envConfig.environments.prod
      ? '264122818946.286640904903'
      : '264122818946.422522617108',
};

export const S3_STATIC_ASSET_ROOT =
  'https://s3.amazonaws.com/tk-prod-static-files';

export const CONTACTS_PRIORITIES = {
  defaultPipeline: 0,
  articleList: 1,
  articleDetail: 2,
  addToList: 3,
  authorCard: 4,
};

// ---------  State Strings ----------
export const NEWSLETTER_STATE = {
  sent: 'SENT',
  draft: 'DRAFT',
};

export const AUTHOR_NOTES_SORT_DATE_OPTIONS = {
  LAST_MOD_DESC: {
    label: 'Last modified (descending)',
    rangeText: 'descending',
    data: 'desc',
  },
  LAST_MOD_ASC: {
    label: 'Last modified (ascending)',
    rangeText: 'ascending',
    data: 'asc',
  },
};

export const AUTHOR_NOTES_SORT_VISIBILITY_OPTIONS = {
  ALL_NOTES: {
    label: 'All notes',
    rangeText: 'All',
    data: 'all',
  },
  MY_NOTES_ONLY: {
    label: 'My notes only',
    rangeText: 'My only',
    data: 'mine',
  },
  TEAM_NOTES_ONLY: {
    label: "My team's notes only",
    rangeText: 'Team only',
    data: 'team',
  },
};

export const AUTHOR_NOTES_NOTES_PER_PAGE = 10;

export const MANAGE_DATA_ITEMS_PER_PAGE = 200;

export const AUTHOR_SOCIAL_TAB_ITEMS_PER_REQUEST = 20;

export const FACETS = {
  blackListedPublications: 'Blacklisted Publications',
  company: 'Company',
  custom: 'Custom',
  filter: 'Filter',
  language: 'Language',
  location: 'Location',
};

export const CONTACT_RELATIONSHIP_STATUSES = [
  {
    id: 'NEW_LEAD',
    label: 'New Lead',
  },
  {
    id: 'ENGAGED',
    label: 'Engaged',
  },
  {
    id: 'OCCASIONAL_COVERAGE',
    label: 'Occasional Coverage',
  },
  {
    id: 'REPEAT_COVERAGE',
    label: 'Repeat Coverage',
  },
  {
    id: 'ADVOCATE',
    label: 'Advocate',
  },
  {
    id: 'DETRACTOR',
    label: 'Detractor',
  },
  {
    id: 'UNRESPONSIVE',
    label: 'Unresponsive',
  },
  {
    id: 'DO_NOT_CONTACT',
    label: 'Do Not Contact',
  },
];

export const DEFAULT_CONTACT_RELATIONSHIP_STATUS_ID = 'NEW_LEAD';

export const NAV_PAGES = {
  campaigns: 'CAMPAIGNS',
  dashboards: 'DASHBOARDS',
  digests: 'DIGESTS',
  discover: 'DISCOVER',
  earnedImpact: 'EARNED_IMPACT',
  earnedMedia: 'EARNED_MEDIA',
  connect: 'CONNECT',
  influencerSavedLists: 'INFLUENCERS_SAVED_LISTS',
  influencerMessageCenter: 'INFLUENCERS_MESSAGE_CENTER',
  influencerOpportunityFeed: 'INFLUENCERS_OPPORTUNITY_FEED',
  influencerMediaRequests: 'INFLUENCERS_MEDIA_REQUEST',
  messageCenter: 'MESSAGE_CENTER',
  newDashboards: 'NEW_DASHBOARDS',
  newReports: 'NEW_REPORTS',
  reports: 'REPORTS',
  savedContacts: 'SAVED_CONTACTS',
  socialListening: 'SOCIAL_LISTENING',
  savedLists: 'SAVED_LISTS',
  searchContacts: 'SEARCH_CONTACTS',
  searches: 'SEARCHES',
  stories: 'STORIES',
  tags: 'TAGS',
  newsletter: 'NEWSLETTER',
};

// color constants

export const COLORS = {
  standard: '#677078',
  disabled: '#cccfd2',
};

//assets gallery constants
export const ASSETS_GALLERY_TYPES = {
  imgGroup: [
    'jpg',
    'jpeg',
    'gif',
    'png',
    'tiff',
    'psd',
    'ai',
    'eps',
    'raw',
    'indd',
    'bmp',
    'wmf',
  ],
  videoGroup: [
    'mp4',
    'mov',
    'avi',
    'mpg',
    'ogg',
    'webm',
    'mpeg',
    'wmv',
    'flv',
    'mp2',
    'mpv',
  ],
  docGroup: [
    'pdf',
    'doc',
    'docx',
    'htm',
    'odt',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'txt',
  ],
};

export const MAXIMUM_ASSETS_IN_ONE_UPLOAD_SHOT = '10';
export const VALID_ASSET_TYPES_FOR_STORY_KIT = [
  'jpg',
  'jpeg',
  'png',
  'mp4',
  'pdf',
];

export const VALID_ASSET_TYPES_FOR_STORY_KIT_FROM_EMAIL = [
  'jpg',
  'jpeg',
  'png',
];

export const DISCLAIMER_LOCAL_SESSION = {
  DISCLAIMER_OPTION: 'DISCLAIMER_OPTION',
};

export const BROWSE_ASSET_LIBRARY_SORT_TYPES = {
  RECENT: 'recent',
  ALPHABETICAL: 'alphabetical',
};
//tabs-browse Asset library
export const BROWSE_ASSET_LIBRARY_TABS = {
  MEDIA_ALL: 1,
  MEDIA_IMAGES: 2,
  MEDIA_VIDEOS: 3,
  MEDIA_DOCS: 4,
};

export const BROWSE_ASSET_LIBRARY_TAGS = {
  FOR_IMAGES: 'IMG',
  FOR_VIDEOS: 'VID',
  FOR_DOCS: 'DOC',
};

export const BROWSE_DOWNLOADABLE_ASSETS_KEYWORD = {
  DOWNLOAD_KEYWORD: 'isBrowseAssetLibraryDownloadableAssetsON',
};

export const BROWSE_ASSET_FROM_SPOKESPERSON_KEYWORD = {
  SPOKE_KEYWORD: 'isBrowseAssetLibraryFromSpokePersonWidget',
};

export const BROWSE_ASSET_LIBRARY_NAME_VALIDATION = {
  NAME_REGEX: /^[a-zA-Z0-9-\s]*$/,
};

export const CONTACT_ATTRIBUTES = 'contact-attributes';
export const IHUB_ADVANCED_SEARCH_TYPES = {
  'influencer-types': {
    title: 'CONTACT TYPE',
    titleI18n: 'constantsIhubAdvancedSearchTypesInfluencerTypes',
    searchCriteriaPropertyName: 'influencerType',
    order: 0,
  },
  'media-types': {
    title: 'MEDIA TYPE',
    titleI18n: 'constantsIhubAdvancedSearchTypesMediaTypes',
    searchCriteriaPropertyName: 'mediaType',
    order: 1,
  },
  'audience-types': {
    title: 'AUDIENCE TYPE',
    titleI18n: 'constantsIhubAdvancedSearchTypesAudienceTypes',
    searchCriteriaPropertyName: 'audienceType',
    order: 2,
  },
  'news-focus': {
    title: 'NEWS FOCUS',
    titleI18n: 'constantsIhubAdvancedSearchTypesNewsFocus',
    searchCriteriaPropertyName: 'newsFocus',
    order: 3,
  },
  frequencies: {
    title: 'OUTLET FREQUENCY',
    titleI18n: 'constantsIhubAdvancedSearchTypesFrequencies',
    searchCriteriaPropertyName: 'frequency',
    order: 4,
  },
  'contact-attributes': {
    title: 'CONTACT ATTRIBUTES',
    titleI18n: 'constantsIhubAdvancedSearchTypesContactAttributes',
    searchCriteriaPropertyName: 'unsubscribed',
    order: 5,
  },
};

const NUMERIC_FILTER_VALUES = [
  25000,
  50000,
  100000,
  250000,
  500000,
  1000000,
  5000000,
  10000000,
  25000000,
];

export const ARTICLE_REACH_SEARCH_FILTER_VALUES = [...NUMERIC_FILTER_VALUES];

export const SOCIAL_REACH_SEARCH_FILTER_VALUES = [
  1000,
  5000,
  ...NUMERIC_FILTER_VALUES,
];

export const FOLLOWER_COUNT_FILTER_VALUES = [...NUMERIC_FILTER_VALUES];

export const IHUB_SORT_BY_TYPES = [
  {
    id: 'relevance',
    label: 'Relevance',
    labelI18n: 'constantsIhubSortByTypesRelevance',
    selected: true,
  },
  {
    id: 'fullname',
    label: 'Name',
    labelI18n: 'constantsIhubSortByTypesName',
    selected: false,
  },
  {
    id: 'article_reach',
    label: 'Article Reach',
    labelI18n: 'constantsIhubSortByTypesArticleReach',
    selected: false,
  },
  {
    id: 'social_reach',
    label: 'Social Reach',
    labelI18n: 'constantsIhubSortByTypesSocialReach',
    selected: false,
  },
  {
    id: 'engagement',
    label: 'Engagement Score',
    labelI18n: 'constantsIhubSortByTypesEngagement',
    selected: false,
  },
  {
    id: 'activity',
    label: 'Weekly Activity',
    labelI18n: 'constantsIhubSortByTypesActivity',
    selected: false,
  },
];

export const PRIVATE_IHUB_SORT_BY_TYPES = [
  {
    id: 'updated_at',
    label: 'Last Update Date',
    labelI18n: 'constantsIhubSortByTypesLastUpdateDate',
    selected: true,
  },
  {
    id: 'fullname',
    label: 'Name',
    labelI18n: 'constantsIhubSortByTypesName',
    selected: false,
  },
  {
    id: 'created_at',
    label: 'Date Created',
    labelI18n: 'constantsIhubSortByTypesDateCreated',
    selected: false,
  },
  {
    id: 'article_reach',
    label: 'Article Reach',
    labelI18n: 'constantsIhubSortByTypesArticleReach',
    selected: false,
  },
  {
    id: 'social_reach',
    label: 'Social Reach',
    labelI18n: 'constantsIhubSortByTypesSocialReach',
    selected: false,
  },
  {
    id: 'engagement',
    label: 'Engagement Score',
    labelI18n: 'constantsIhubSortByTypesEngagement',
    selected: false,
  },
  {
    id: 'activity',
    label: 'Weekly Activity',
    labelI18n: 'constantsIhubSortByTypesActivity',
    selected: false,
  },
];

export const PRIVATE_DEFAULT_SEARCH_CRITERIA = {
  direction: 'desc',
  field: 'updated_at',
};

export const SEARCH_PAGE_DEFAULT_SORT_CRITERIA = {
  direction: 'desc',
  field: 'relevance',
};

export const NAVIGATION_ARROW_SIZE = 24;
export const PRIMARY_NAV_HEIGHT = 70;
export const SECONDARY_NAV_HEIGHT = 60;

export const IHUB_SAVED_LISTS_FILTER_COLUMNS_OLD = [
  {
    id: 'name',
    label: 'Sort by: Name',
    labelI18n: 'constantsSortByName',
    selected: false,
  },
  {
    id: 'owner',
    label: 'Sort by: Owner',
    labelI18n: 'constantsSortByOwner',
    selected: false,
  },
  {
    id: 'lastUpdated',
    label: 'Sort by: Last Updated',
    labelI18n: 'constantsSortByLastUpdated',
    selected: true,
  },
  {
    id: 'influencersCount',
    label: 'Sort by: Contacts Count',
    labelI18n: 'constantsSortByContactsCount',
    selected: false,
  },
];

export const IHUB_SAVED_LISTS_FILTER_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    labelI18n: 'constantsSortByName',
    selected: false,
  },
  {
    id: 'last_updated',
    label: 'Last Updated',
    labelI18n: 'constantsSortByLastUpdated',
    selected: true,
  },
  {
    id: 'total_influencers',
    label: 'Contacts Count',
    labelI18n: 'constantsSortByContactsCount',
    selected: false,
  },
];

export const IHUB_INFLUENCER_KEBAB_ACTIONS = {
  addToAnotherList: 1,
  removeFromList: 2,
  editInfluencer: 3,
  deletePrivateContact: 4,
};

export const IHUB_SAVED_LIST_SHARE_PERMISSIONS = [
  {
    id: 'none',
    label: 'Private (only visible to you)',
    labelI18n: 'constantsIhubListSharePermissionPrivate',
    selected: true,
  },
  {
    id: 'r',
    label: 'View Only (can only view list, no editing allowed)',
    labelI18n: 'constantsIhubListSharePermissionViewOnly',
    selected: false,
  },
  {
    id: 'rw',
    label: 'Full Access (can add, edit and delete)',
    labelI18n: 'constantsIhubListSharePermissionFull',
    selected: false,
  },
];

export const STORY_KIT_SHARE_PERMISSIONS = [
  {
    id: 'none',
    label: 'Private (Only visible to you)',
    labelI18n: 'constantsStoryKitSharePermissionPrivate',
    selected: true,
  },
  {
    id: 'r',
    label: 'View Only (Can only view, no editing allowed)',
    labelI18n: 'constantsStoryKitSharePermissionViewOnly',
    selected: false,
  },
  {
    id: 'rw',
    label: 'Full Access (Can add, edit and delete)',
    labelI18n: 'constantsStoryKitSharePermissionFull',
    selected: false,
  },
];

export const STORY_KIT_SHARE_LEVELS = {
  view: 'VIEW',
  edit: 'EDIT',
};

const KEYWORDS_STREAM_SEARCH_THREE_LETTERS_FILTER_PATTERN = '\\p{L}{3}';
const KEYWORDS_STREAM_SEARCH_WORD_AND_PHRASE_FILTER_PATTERN =
  '(\\p{L}{1,}|"\\p{L}[\\p{L}\\p{Zs}]*")';
const KEYWORDS_STREAM_SEARCH_SENTIMENT_FILTER_PATTERN =
  '(positive|negative|neutral|unknown){1}';
const KEYWORDS_STREAM_SEARCH_URL_AND_SITE_FILTERS_PATTERN = '[\\p{L}\\p{P}]+';
const KEYWORDS_STREAM_SEARCH_MEDIA_FILTER_PATTERN = '(image|video){1}';
const KEYWORDS_STREAM_SEARCH_TWITTER_FOLLOWERS_FILTER_PATTERN =
  '(\\p{N}{1,}(\\.{2}\\p{N}{0,})?|\\.{2}\\p{N}{1,})';
const KEYWORDS_STREAM_SEARCH_GENDER_FILTER_PATTERN =
  '(male|female|undetermined){1}';

export const KEYWORDS_STREAM_SEARCH_FILTERS_DATA = [
  {
    text: 'language',
    valuePattern: KEYWORDS_STREAM_SEARCH_THREE_LETTERS_FILTER_PATTERN,
  },
  {
    text: 'country',
    valuePattern: KEYWORDS_STREAM_SEARCH_THREE_LETTERS_FILTER_PATTERN,
  },
  {
    text: 'place',
    valuePattern: KEYWORDS_STREAM_SEARCH_WORD_AND_PHRASE_FILTER_PATTERN,
  },
  {
    text: 'sentiment',
    valuePattern: KEYWORDS_STREAM_SEARCH_SENTIMENT_FILTER_PATTERN,
  },
  {
    text: 'url',
    valuePattern: KEYWORDS_STREAM_SEARCH_URL_AND_SITE_FILTERS_PATTERN,
  },
  {
    text: 'site',
    valuePattern: KEYWORDS_STREAM_SEARCH_URL_AND_SITE_FILTERS_PATTERN,
  },
  { text: 'media', valuePattern: KEYWORDS_STREAM_SEARCH_MEDIA_FILTER_PATTERN },
  {
    text: 'twitterfollowers',
    valuePattern: KEYWORDS_STREAM_SEARCH_TWITTER_FOLLOWERS_FILTER_PATTERN,
  },
  {
    text: 'gender',
    valuePattern: KEYWORDS_STREAM_SEARCH_GENDER_FILTER_PATTERN,
  },
];

export const TWITTER_BASE_URL = 'https://twitter.com/';

export const NEW_EARNED_SEARCHES_REQUEST_PARAM = 'useNewEarnedSearch';

export const ADVANCED_SEARCH_EDITOR_DECORATORS_COLORS = {
  tokensColor: '#F92672',
  filterColor: '#F92672',
  stringLiteralColor: '#E6DB74',
  nearOperatorColor: '#90f',
};

export const TAG_TYPE = {
  CAMPAIGN: 'CAMPAIGN',
  REGULAR: 'REGULAR',
};

export const ACG_OBJECTS_TYPES = {
  influencerList: 'influencerlist',
  tag: 'tag',
  newsletter: 'newsletter',
};

const ACG_COMMON_SHARING_LEVELS = {
  viewOnly: {
    id: 'VIEWONLY',
    label: 'viewOnly',
  },
  fullAccess: {
    id: 'FULL',
    label: 'fullAccess',
  },
};

export const ACG_OBJECTS_SHARING_LEVELS = {
  [ACG_OBJECTS_TYPES.influencerList]: [
    ACG_COMMON_SHARING_LEVELS.viewOnly,
    ACG_COMMON_SHARING_LEVELS.fullAccess,
  ],
  [ACG_OBJECTS_TYPES.tag]: [ACG_COMMON_SHARING_LEVELS.fullAccess],
  [ACG_OBJECTS_TYPES.newsletter]: [ACG_COMMON_SHARING_LEVELS.fullAccess],
};

export const TK_SYSTEM_USER = 'TK_SYSTEM_USER';

export const FEATURE_MENU_WHITELIST_VALIDATORS = [
  pathname => pathname.startsWith(BASIC_EARNED_SEARCH_URL),
  pathname => pathname.startsWith(ADVANCED_EARNED_SEARCH_URL),
  pathname => pathname !== DASHBOARD_URL && pathname.startsWith(DASHBOARD_URL),
  pathname => pathname.startsWith(`/#${WIDGET_DRILLDOWN_URL}`),
];

export const BAD_REQUEST_STATUS_CODE = 400;

export const FORBIDDEN_STATUS_CODE = 403;

export const ENTITY_TOO_LARGE_STATUS_CODE = 413;

export const SOCIAL_SEARCH_NETWORKS = {
  twitter: 'TWITTER',
};

export const SOCIAL_SEARCH_FIELDS_TO_OMIT = [
  'dateCreated',
  'dateDeleted',
  'lastSearched',
  'lastUpdated',
];

export const TVEYES_EXPIRATION_PERIOD = 28; //this period was set in the BE here is just a copy of that

export const WIDGET_V3_FOOTER_DATE_RANGE_MODIFIERS = ['widget-V3-footer'];

export const ArticleListContext = {
  discovery: 'discovery',
  earnedSearches: 'earned-searches',
  tags: 'tags',
  widgetDrilldown: 'widget-drilldown',
};

export const DELETE_ATTACHMENT_STATUS = {
  cancelled: 'cancelled',
  confirmed: 'confirmed',
};

export const ADMIN_USER_OPTIONS = {
  showUsers: 'ADMIN_USER_MANAGEMENT_SIDEBAR_SHOW_USERS_LIST',
};

export const ADMIN_ACCOUNT_OPTIONS = {
  searchAccount: 'ADMIN_ACCOUNT_MANAGEMENT_SIDEBAR_SEARCH_ACCOUNT',
  addAccount: 'ADMIN_ACCOUNT_MANAGEMENT_SIDEBAR_ADD_ACCOUNT',
  accountList: 'ADMIN_ACCOUNT_MANAGEMENT_SIDEBAR_ACCOUNT_LIST',
  accountAssetMigration: 'ADMIN_ACCOUNT_MANAGEMENT_SIDEBAR_ASSET_MIGRATION',
};

export const ADMIN_ANALYSIS_OPTIONS = {
  featureList: 'ADMIN_ANALYSIS_FEATURE_LIST',
  devFeatureList: 'ADMIN_ANALYSIS_DEV_FEATURE_LIST',
};

export const ADMIN_ARTICLE_OPTIONS = {
  addArticle: 'ADMIN_ARTICLE_MANAGEMENT_SIDEBAR_ADD_ARTICLE',
  editArticle: 'ADMIN_ARTICLE_MANAGEMENT_SIDEBAR_EDIT_ARTICLE',
  searchArticle: 'ADMIN_ARTICLE_MANAGEMENT_SIDEBAR_SEARCH_ARTICLE',
};

export const OUTREACH_EMAIL_ACCOUNT_TYPE = {
  verification: 'VERIFICATION',
  integration: 'INTEGRATION',
  username: 'USERNAME',
};

export const XLSX_FORMAT =
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const ADMIN_DEFAULT_DEBOUNCE_TIME = 500;

export const MAX_NUMBER_OF_URLS_IN_BULK_ADD = 25;
export const MAX_NUMBER_OF_ARTICLES_PER_BULK_OPERATION = 1000;
export const ADDED_ARTICLE_STATUS = {
  pending: {
    id: 'pending',
    priority: 3,
  },
  processing: {
    id: 'processing',
    priority: 3,
  },
  failed: {
    id: 'failed',
    priority: 2,
  },
  toBeAdded: {
    id: 'toBeAdded',
    priority: 1,
  },
  exists: {
    id: 'exists',
    priority: 4,
  },
  duplicatedInSearch: {
    id: 'duplicatedInSearch',
    priority: 5,
  },
};

export const environments = {
  'dev.trendkite.com': 'dev',
  'staging.trendkite.com': 'staging',
  'app.trendkite.com': 'prod',
  'app2.trendkite.com': 'app2',
  'app2.cision.com': 'prod',
};

export const DOMAIN_DKIM_STATUS = {
  valid: 'Valid',
  invalid: 'Invalid',
  notCreated: 'Not Created',
};

export const ADMIN_TABS = {
  accountManagement: 'ADMIN_ACCOUNT_MANAGEMENT',
  articleManagement: 'ARTICLE_MANAGEMENT',
  importCustomer: 'IMPORT_CUSTOMER',
  alertSubscriptions: 'ALERT_SUBSCRIPTIONS',
  feed: 'ADMIN_FEED',
  importContactList: 'ADMIN_IMPORT_CONTACT_LIST',
  userManagement: 'ADMIN_USER_MANAGEMENT',
  analysisManagement: 'ADMIN_ANALYSIS_MANAGEMENT',
  importCustomerV2: 'IMPORT_CUSTOMER_V2',
  dkimManagement: 'DKIM_MANAGEMENT',
  demoUser: 'DEMO_USER',
};

export const CONTENT_ENGAGEMENT_EVENTS_TYPES = {
  viewFullText: 'VIEW_FULL_TEXT',
  viewOriginalArticle: 'VIEW_ORIGINAL_ARTICLE',
};

export const CONTENT_ENGAGEMENT_CONTENT_SOURCES = {
  newYorkTimesOnline: 'NEW_YORK_TIMES_ONLINE',
  newYorkTimesPrint: 'NEW_YORK_TIMES_PRINT',
};

export const CONTENT_ENGAGEMENT_CONTENT_SOURCES_MAP = {
  [`${MEDIA_TYPES.news.apiKey}_${DATA_SOURCE.newYorkTimesOnline.apiKey}`]: CONTENT_ENGAGEMENT_CONTENT_SOURCES.newYorkTimesOnline,
  [`${MEDIA_TYPES.printData.apiKey}_${DATA_SOURCE.newYorkTimesPrint.apiKey}`]: CONTENT_ENGAGEMENT_CONTENT_SOURCES.newYorkTimesPrint,
};

export const PRIVATE_CONTACTS_LIST_ID = 'private-all';

export const EMAIL_ANNOUNCEMENT_CONTENT_SIZE_LIMIT = 18;

export const LANGUAGES_FOR_HELP_SITE_LINKS_UPDATE = [
  'nl-nl',
  'en-se',
  'en-gb',
  'fi-fi',
  'de-de',
  'no-no',
  'pt-pt',
  'sv-se',
  'en-ca',
  'en-us',
  'fr-ca',
  'fr-fr',
  'es-mx',
];

export const WIDGETS_V3_BACKEND_CACHE_RETRY_LIMIT = 12;
export const WIDGETS_V3_BACKEND_CACHE_RETRY_INTERVAL = 10;
