import {
  influencerSearchInfluencersLoadingSelector,
  searchIsLoadingSelector,
} from 'pages/Influencers/Search/selectors';

export type Matcher = string | RegExp;

export type TrackerConfig = {
  matcher: Matcher;
  selectorForIsLoading?: (arg0: any) => void;
};

export const pageTrackerConfigs: TrackerConfig[] = [
  { matcher: '/influencers/lists' },
  { matcher: '/influencers/messages/all' },
  { matcher: '/influencers/private-influencers' },
  { matcher: '/influencers/saved-lists' },
  { matcher: '/influencers/media-requests' },
  { matcher: new RegExp('\\/influencers\\/([-\\w])+\\/([-\\w])+$') },
  { matcher: new RegExp('\\/influencers\\/([-\\w])+$') },
  { matcher: new RegExp('\\/influencers\\/messages\\/([-\\w])+$') },
  {
    matcher: '/influencers',
    selectorForIsLoading: searchIsLoadingSelector,
  },
  {
    matcher: '/influencers/',
    selectorForIsLoading: searchIsLoadingSelector,
  },
  {
    matcher: '/influencers/search',
    selectorForIsLoading: influencerSearchInfluencersLoadingSelector,
  },
  { matcher: new RegExp('\\/dashboard\\/([-\\w])+$') },
];
