/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import globalI18nMessages from 'i18n/Global.messages';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Checkbox from '../forms/Checkbox';
import globalMessages from '../Global.messages';
import RadioButton from '../radio-button';
import SvgIcon from '../svg-icon';

import Tooltip from '../tooltip';

const ListEntry = ({
  className,
  disabled,
  entry,
  iconHeight,
  iconWidth,
  multiselect,
  onSelect,
  selected,
  removeIcon,
  idx,
  ...passedProps
}) => {
  const titleDivRef = useRef();
  const intl = useIntl();
  const [hasTitleOverflow, setHasTitleOverflow] = useState(false);
  const {
    alreadyAdded,
    isReadOnly,
    subtitle,
    subtitleLabel,
    infoIcon,
    listLimitReached,
  } = entry;
  const addingDisabled = useMemo(() => disabled && disabled.max, [disabled]);

  // Update title overflow status once
  useLayoutEffect(() => {
    if (titleDivRef.current) {
      const titleName = titleDivRef.current;

      if (
        titleName.offsetHeight < titleName.scrollHeight ||
        titleName.offsetWidth < titleName.scrollWidth
      ) {
        setHasTitleOverflow({ hasTitleOverflow: true });
      }
    }
  }, []);

  const handleSelect = useCallback(() => {
    if (
      entry.alreadyAdded ||
      entry.isReadOnly ||
      entry.listLimitReached ||
      (addingDisabled && !selected)
    ) {
      return;
    }

    onSelect(entry);
  }, [addingDisabled, entry, onSelect, selected]);

  const selectionOption = useMemo(() => {
    let selectionOption;
    if (alreadyAdded) {
      selectionOption = <div> {intl.formatMessage(globalMessages.added)} </div>;
    } else if (listLimitReached) {
      selectionOption = (
        <div className="tk-list-entry__limit-reached">
          {intl.formatMessage(globalMessages.listLimitReached)}
        </div>
      );
    } else if (isReadOnly) {
      selectionOption = (
        <div className="tk-list-entry__view-only">
          {intl.formatMessage(globalMessages.viewOnly)}
        </div>
      );
    } else if (!multiselect) {
      selectionOption = (
        <div className="tk-list-entry__single-select">
          <RadioButton selected={selected} />
        </div>
      );
    } else if (disabled && disabled.max && !selected) {
      selectionOption = (
        <div className="tk-list-entry__checkbox">
          <Checkbox disabled size="medium" />
        </div>
      );
    } else {
      selectionOption = (
        <div className="tk-list-entry__checkbox">
          <Checkbox selected={selected} size="medium" />
        </div>
      );
    }

    return selectionOption;
  }, [
    disabled,
    multiselect,
    selected,
    listLimitReached,
    alreadyAdded,
    isReadOnly,
    intl,
  ]);

  const classes = classNames('tk-list-entry', className, {
    'tk-list-entry--no-subtitle': !subtitle,
    'tk-list-entry--unavailable':
      alreadyAdded || isReadOnly || listLimitReached,
    'tk-list-entry--untitled':
      entry.title ===
      `[${intl.formatMessage(globalI18nMessages.alternativeTitleForStory)}]`,
  });

  const icon = entry.svgIcon ? entry.svgIcon : 'users';
  const title = entry.titleString ? entry.titleString : entry.title;
  const { toolTipPosition } = passedProps;
  const position = toolTipPosition && toolTipPosition[idx];

  return (
    <div
      data-qa="eCDmUftUpyYyqIRhzcCmH"
      className={classes}
      onClick={handleSelect}
      role="button"
      tabIndex="0"
    >
      {!removeIcon && (
        <div className="tk-list-entry__icon">
          <SvgIcon icon={icon} height={iconHeight} width={iconWidth} />
        </div>
      )}
      <div className="tk-list-entry__data">
        {hasTitleOverflow ? (
          <Tooltip
            content={title}
            contentWidth={220}
            position={position || 'topleft'}
          >
            <div ref={titleDivRef} className="tk-list-entry__data-title">
              {title}
            </div>
          </Tooltip>
        ) : (
          <div ref={titleDivRef} className="tk-list-entry__data-title">
            {title}
          </div>
        )}
        {subtitle && (
          <div className="tk-list-entry__data-subtitle">{`${
            subtitleLabel ? `${subtitleLabel}:` : ''
          } ${subtitle}`}</div>
        )}
      </div>
      {infoIcon && (
        <div className="tk-list-entry__info-icon">
          <SvgIcon icon={infoIcon} />
        </div>
      )}
      {selectionOption}
    </div>
  );
};

ListEntry.defaultProps = {
  className: '',
  disabled: undefined,
  multiselect: false,
  onSelect: () => {},
  selected: false,
  iconHeight: 14,
  iconWidth: 14,
  removeIcon: false,
  radioDataQA: null,
};

ListEntry.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.shape({
    max: PropTypes.bool,
  }),
  entry: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitle: PropTypes.string,
    subtitleLabel: PropTypes.string,
    svgIcon: PropTypes.string,
    infoIcon: PropTypes.string,
    alreadyAdded: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    titleString: PropTypes.string,
    influencerIds: PropTypes.array,
    listLimitReached: PropTypes.bool,
  }).isRequired,
  multiselect: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  removeIcon: PropTypes.bool,
  radioDataQA: PropTypes.string,
  idx: PropTypes.number,
};

export default ListEntry;
