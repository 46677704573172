function selectedHelpString(bits, strs) {
  for (let i = 0; i < bits.length; i++) {
    if (bits[i]) {
      return strs[i];
    }
  }
  return '';
}

export default function urlModifier() {
  let mod = '';
  // react
  const loc = window.location.hash.substring(2);
  // angular
  const pat = window.location.pathname.substring(1);
  const lang = window.activeUser.language;

  //we only have 3 custom helps the others all use english
  //de-de help appears to be just a shell with no/little content

  const englishEquivalent =
    lang !== 'fr-ca' && lang !== 'fr-fr' && lang !== 'de-de'; // PUT THIS back in when we get good german help https://communicationscloud-de.knowledgeowl.com/help

  const frFr = lang === 'fr-fr';
  const frCa = lang === 'fr-ca';
  const deDe = lang === 'de-de';
  const langFlags = [englishEquivalent, frFr, frCa, deDe];

  const defaultLink = '/article/25765216446';

  switch (true) {
    //Coverage
    case loc === 'earned-searches':
      mod = selectedHelpString(langFlags, [
        '/article/25765904574', //eng
        '/article/25808044078', //fr-fr
        '/article/25808044078', //fr-ca
        '/article/25776390573', //de-de
      ]);
      break;

    case loc === 'tags':
      mod = selectedHelpString(langFlags, [
        '', //eng
        '', //fr-fr
        '', //fr-ca
        '', //de-de
      ]);
      break;

    case loc.indexOf('earned-searches/advanced') === 0:
      mod = selectedHelpString(langFlags, [
        '/article/25766068389', //eng
        '/article/25807945952', //fr-fr
        '/article/25807945952', //fr-ca
        '', //de-de
      ]);
      break;

    case loc.indexOf('earned-searches/simple') === 0:
      mod = selectedHelpString(langFlags, [
        '/article/25765022674', //eng
        '/article/25807913271', //fr-fr
        '/article/25807913271', //fr-ca
        '/article/25750733711', //de-de
      ]);
      break;

    case loc.indexOf('browse/top-content') === 0:
      mod = selectedHelpString(langFlags, [
        '', //eng
        '', //fr-fr
        '', //fr-ca
        '', //de-de
      ]);
      break;

    // Influencers
    case loc.indexOf('contacts') === 0:
      mod = selectedHelpString(langFlags, [
        defaultLink, //eng
        defaultLink, //fr-fr
        defaultLink, //fr-ca
        defaultLink, //de-de
      ]);
      break;

    // Contacts
    //search
    case loc === 'influencers':
      mod = selectedHelpString(langFlags, [
        '/article/25766298739', //eng
        '/article/25807323403', //fr-fr
        '/article/25807323403', //fr-ca
        '/article/25776521391', //de-de
      ]);
      break;

    //Lists
    case loc === 'influencers/saved-lists':
      mod = selectedHelpString(langFlags, [
        '/article/25766593843', //eng
        '/article/25807323403', //fr-fr
        '/article/25807323403', //fr-ca
        '/article/25776488811', //de-de
      ]);
      break;

    //Message Center
    case loc === 'influencers/messages/all':
      mod = selectedHelpString(langFlags, [
        '/article/25766561782', //eng
        '/article/25810665933', //fr-fr
        '/article/25810665933', //fr-ca
        '', //de-de
      ]);
      break;

    //Media Requests
    case loc === 'influencers/media-requests':
      mod = selectedHelpString(langFlags, [
        '', //eng
        '', //fr-fr
        '', //fr-ca
        '', //de-de
      ]);
      break;

    //Ananlytics
    //V2
    case loc === 'dashboard':
      mod = selectedHelpString(langFlags, [
        '/article/25768559070', //eng
        '/article/25808144248', //fr-fr
        '/article/25808144248', //fr-ca
        defaultLink, //de-de
      ]);
      break;

    case loc.indexOf('dashboard') === 0:
      mod = selectedHelpString(langFlags, [
        '/article/25768559070', //eng
        '/article/25807946223', //fr-fr
        '/article/25807946223', //fr-ca
        defaultLink, //de-de
      ]);
      break;

    //V1
    case pat === 'dashboard':
      mod = selectedHelpString(langFlags, [
        defaultLink, //eng
        defaultLink, //fr-fr
        defaultLink, //fr-ca
        '', //de-de
      ]);
      break;

    case loc === 'impact/earned':
      mod = selectedHelpString(langFlags, [
        '/article/25768657689', //eng
        '/article/25810600267', //fr-fr
        '/article/25810600267', //fr-ca
        defaultLink, //de-de
      ]);
      break;

    case loc.indexOf('campaigns') === 0:
      mod = selectedHelpString(langFlags, [
        '/article/25768394941', //eng
        '/article/25810895093', //fr-fr
        '/article/25810895093', //fr-ca
        '', //de-de
      ]);
      break;

    case loc.indexOf('stories') === 0 && loc.indexOf('content') !== -1:
      mod = selectedHelpString(langFlags, [
        '/article/25768329394', //eng
        '/article/25810862590', //fr-fr
        '/article/25810862590', //fr-ca
        '', //de-de
      ]);
      break;

    case loc.indexOf('stories') === 0:
      mod = selectedHelpString(langFlags, [
        '/article/25768329394', //eng
        '/article/25810797038', //fr-fr
        '/article/25810797038', //fr-ca
        '', //de-de
      ]);
      break;

    case loc.indexOf('report') === 0:
      mod = selectedHelpString(langFlags, [
        '/article/25768592235', //eng
        '/article/25808044651', //fr-fr
        '/article/25808044651', //fr-ca
        defaultLink, //de-de
      ]);
      break;

    case loc === 'digest/home':
      mod = selectedHelpString(langFlags, [
        '/article/25768658104', //eng
        '/article/25808177035', //fr-fr
        '/article/25808177035', //fr-ca
        '', //de-de
      ]);
      break;

    case loc === 'alerts/newsletter':
      mod = selectedHelpString(langFlags, [
        '/article/25768461266', //eng
        '/article/25768461266', //fr-fr
        '/article/25768461266', //fr-ca
        '/article/25775931788', //de-de
      ]);
      break;
    case loc.endsWith('newsletter/add'):
      mod = selectedHelpString(langFlags, [
        '', //eng
        '', //fr-fr
        '', //fr-ca
        '', //de-de
      ]);
      break;
    case loc.includes('newsletter/edit/'):
      mod = selectedHelpString(langFlags, [
        '', //eng
        '', //fr-fr
        '', //fr-ca
        '', //de-de
      ]);
      break;
    case loc === 'social-listening':
      mod = selectedHelpString(langFlags, [
        defaultLink, //eng
        defaultLink, //fr-fr
        defaultLink, //fr-ca
        defaultLink, //de-de
      ]);
      break;

    case loc === 'settings/account/integrations':
    case loc === 'settings/manage':
    case loc === 'settings/user-groups':
      mod = selectedHelpString(langFlags, [
        '/article/25765021781', //eng
        defaultLink, //fr-fr
        defaultLink, //fr-ca
        defaultLink, //de-de
      ]);
      break;

    case loc === 'settings/account/mail':
      mod = selectedHelpString(langFlags, [
        defaultLink, //eng
        defaultLink, //fr-fr
        defaultLink, //fr-ca
        defaultLink, //de-de
      ]);
      break;
  }
  return mod;
}
